import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService, ErxService, NotesLogService, SecurityManagerService } from 'src/app/services';
import { PrintService } from 'src/app/services/print.service';
import * as moment from 'moment';
import { CommonUtil, NRxUtils } from 'src/app/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonNotesLogComponent } from '../common-notes-log/common-notes-log.component';
import { PatientStore } from 'src/app/store/patient.store';
import { ERxDetailsViewModel, PaginationModel, searchTrackPatientCriteria } from 'src/app/models';
import { MsgConfig } from 'src/app/app.messages';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { RxStore } from 'src/app/store';
import { NotesLogComponent } from '../notes-log/notes-log.component';
import { FormatsUtil } from 'src/app/utils/formats.util';
import html2canvas from "html2canvas";
@Component({
    selector: 'app-electronic-rx-doc',
    templateUrl: './electronic-rx-doc.component.html'
})
export class ElectronicRxDocComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    @Output() GetRxNotesList =  new EventEmitter<boolean>();
    @Input()  IsNotesDisabled: boolean;
    NotesAvailability: boolean = false;
    detailsInfo: any;
    index: number;
    rxOrdDt: string;
    EfctDt: string;
    IsNotes: boolean = false;
    modelRef : any;
    allNotes: any = [];
    rxDetailsViewModel: any;
    rxInfo: any;
    systemData$: Observable<any>;
    drugAuditData: any= [];
    prescriberAuditData: any = [];
    patientAuditData: any =[];
    notesLogs: any ={
        Note: [],
        NoteCat: [],
        AnnotationNotes: [],
        PatientNotes: [],
        InsuranceNotes: [],
        DrugNotes: [],
        PrescriberNotes: [],
        ERXNotes: [],

    };
    rxInfoFrmVrf: any;
    IsRxNotesAvaiable: boolean = false;
    IsDisctFromErx: boolean = false;
    unsubscribe$: Subject<void> = new Subject();
    selectedRxItem: any = {};
    searchParams: searchTrackPatientCriteria;
    NotesList: any  = [];
    NotesListSave: any  = [];
    IsNeweRx: boolean = false;
    @Input()
    set ErxDocData(data: any) {
        this.detailsInfo = data;
        console.log( this.detailsInfo);
        if (this.detailsInfo && this.detailsInfo.RxOrdDt) {
            this.rxOrdDt = moment.utc(this.detailsInfo.RxOrdDt).local().format("MM/DD/YYYY");
        }
        if (this.detailsInfo && this.detailsInfo.EffectiveDate) {
            this.EfctDt = moment.utc(this.detailsInfo.EffectiveDate).local().format("MM/DD/YYYY");
        }
        if (this.detailsInfo){
            this.detailsInfo.auth = this.detailsInfo && this.detailsInfo?.MsgType === "RxRenewalResponse" && +(this.detailsInfo?.RefillNum) > 0 ? (+(this.detailsInfo?.RefillNum) - 1) : +(this.detailsInfo?.RefillNum) ;
                this.detailsInfo.totalDispensings = this.detailsInfo?.auth ? (+(this.detailsInfo?.auth) + 1) : 1;
        }
    }
    @Input()  IsAutoGenerate;
    @Input()  IsFromRoute;
    @Input()  IsFromQuickVerification;
    
    
    @Input()
    set RxInfoFromVerifications(data: any) {
        if(data){
            this.rxInfoFrmVrf = data;
            this.patchRxValuesForRxAutoGenerateDoc(data);
        }   
    }
    @Input()
    set rxFG(data: FormGroup) {
        if (data) {
            console.log(data , "datadata");
            let fgVal = this.rxInfo = data.getRawValue();
            this.detailsInfo = fgVal.eRxDetailsViewModel;
            if (this.detailsInfo && this.detailsInfo.RxOrdDt) {
                this.rxOrdDt = moment.utc(this.detailsInfo.RxOrdDt).local().format("MM/DD/YYYY");
            }
            if (this.detailsInfo && this.detailsInfo.EffectiveDate) {
                this.EfctDt = moment.utc(this.detailsInfo.EffectiveDate).local().format("MM/DD/YYYY");
            }
            if (this.IsAutoGenerate) {
                console.log(this.rxFG, "Hellloo");
            }
            if (this.detailsInfo && this.detailsInfo?.RefillNum != null){
                this.detailsInfo.auth = this.detailsInfo && this.detailsInfo?.MsgType === "RxRenewalResponse" && +(this.detailsInfo?.RefillNum) > 0 ? (+(this.detailsInfo?.RefillNum) - 1) : +(this.detailsInfo?.RefillNum) ;
                this.detailsInfo.totalDispensings = this.detailsInfo?.auth ? (+(this.detailsInfo?.auth) + 1) : 1;
            }
                
        }
    }
    
    @Input() documentsType;
   
    constructor(private _commonSvc: CommonService,private _printSer: PrintService, private erxService: ErxService, private _rxStore: RxStore,
        public _commonUtil: CommonUtil,  private _modalService: NgbModal, private _patStore: PatientStore, private _cdr: ChangeDetectorRef,  private _secuManager: SecurityManagerService, private _nrxUtls: NRxUtils,  private _notesLogSvc: NotesLogService, public _formatsUtil:FormatsUtil) {
            this.systemData$ = this._commonSvc.systemData$;
         }

    ngOnInit() {
         this.IsNeweRx = this.rxInfo ? (this.rxInfo?.rxType === "nr" && this.rxInfo?.eRxDetailsViewModel?.ErxId) : false;
        const rxInfo = this._rxStore.rxInfo$["source"]["value"];
        this.IsDisctFromErx  = rxInfo?.Prescription?.IsDisctFromErx ?  true : false;
        if ((this.detailsInfo?.IsNormalRx || this.IsFromRoute) && ((this.rxInfo && this.rxInfo['PrescReFill'] && this.rxInfo['PrescReFill']["Id"]) || (this.rxInfoFrmVrf && this.rxInfoFrmVrf['PrescReFill'] && this.rxInfoFrmVrf['PrescReFill']["Id"])))
        this.getAuditLog();
        this.getNotes();
    }
    ngOnChanges(changes: SimpleChanges) {
    }
    async getALLNotes() { 
        //the rxInfoFrmVrf varaible data will be come from verification screen's.
        this.notesLogs = await this._nrxUtls.getNotesLog("RxAnnotation", (this.rxInfo) ? this.rxInfo["Prescription"]["Id"] : this.rxInfoFrmVrf["Prescription"]["Id"] , {PrescReFill : 
            this.rxInfo ?  this.rxInfo["PrescReFill"]["Id"] : this.rxInfoFrmVrf["Prescription"]["Id"] , PrescNum : this.rxInfo ? this.rxInfo["Prescription"]["PrescNum"] : this.rxInfoFrmVrf["Prescription"]["PrescNum"] }) as any;
        if(this.notesLogs) {

            if(this.notesLogs?.AnnotationNotes?.length) {
                this.notesLogs.Note =  this.notesLogs.Note.concat(this.notesLogs.AnnotationNotes);
            }
            if(!this.modelRef) {
                this.modelRef = this._modalService.open(CommonNotesLogComponent,{keyboard : false, backdrop : false, centered : true , windowClass: "max-modal-970"});
                this.modelRef.componentInstance.NotesListFrmPage = this.notesLogs;
                this.modelRef.componentInstance.HeaderText = "Consolidated Notes";
                this.modelRef.componentInstance.IsNotesLogPopupClosed.subscribe((res : any)=>{
                    if( this.modelRef)
                    {
                        this.modelRef.close();
                        this.modelRef = null;
                    }
                })
            }
           
        }
    }
    async printDocument() {
        if (this.detailsInfo && this.detailsInfo && !this.detailsInfo.IsNormalRx) {
            let notes = []
            if(this.rxInfo?.rxType === 'nr'){
                notes = this.rxInfo?.RxNotes?.map(rxNote => ({
                    NoteType: rxNote.Name,
                    Notes: rxNote.xNote,
                    NoteCatId : rxNote.NoteCatId
                  }));
            }
            const payload = [{
                eRxId: this.detailsInfo.ErxId ? this.detailsInfo.ErxId : 0,
                eRxOperationId: this.detailsInfo.ErxOperationId ? this.detailsInfo.ErxOperationId : 0,
                Notes : notes?.length && this.rxInfo?.rxType === 'nr' ? notes : null
            }]
            const ErxResp = await this.erxService.getErxMultiReport(payload).toPromise();
            if (ErxResp && ErxResp.size > 0 && ErxResp.type === "application/pdf") {
                this.print(ErxResp);
            }
        } else {
            const rxAnnotationResp = await this.erxService.getAnnoationReport({ prescRefillId: this.detailsInfo.prescRefillId }).toPromise();
            if (rxAnnotationResp && rxAnnotationResp.size > 0 && rxAnnotationResp.type === "application/pdf") {
                this.print(rxAnnotationResp);
            }
        }
    }

    print(datToPrint) {
        const newBlob = new Blob([datToPrint], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printSer.printPdf(base64data);
        }.bind(this);
    }
    async getAuditLog() {
        const search = {
            Document: "Rx",
            Function: "EditRx",
            FromLogDate: null,
            ToLogDate: null,
            PageSize: null,
            PageNumber: null,
            entityId:Number((this.rxInfoFrmVrf && this.rxInfoFrmVrf['PrescReFill']["Id"]) ? this.rxInfoFrmVrf['PrescReFill']["Id"] : this.rxInfo['PrescReFill']["Id"])
        };
        const resp: any = await this._secuManager.getAuditLog(search).toPromise();
        if (resp && resp.AuditViewModel && resp.AuditViewModel?.length) {
            //To get Patient Track details
            resp.AuditViewModel.map((newItem: any) => {
                if (newItem && newItem.delta && newItem.delta.changed && newItem.delta.changed.length && newItem.delta.original && newItem.delta.original.length) {
                    newItem.delta.original.map((audit, i) => {
                        const oldVal = audit.value;
                        const newVal = (newItem.delta.changed && newItem.delta.changed[i]) ? newItem.delta.changed[i].value : "";
                        const k = {};
                        k["Funtion"] = newItem.action;
                        k["Column"] = audit.name;
                        k["OldValue"] = oldVal ? (this._commonUtil.checkDateTimeFields(oldVal) ?
                        (audit.name === "Measure Time" || audit.name === "Picked Up Time"? moment.utc(oldVal).local().format("HH:mm:ss") :
                         moment.utc(oldVal).local().format("MM/DD/YYYY")) : oldVal) : oldVal;
                        k["NewValue"] = newVal ? (this._commonUtil.checkDateTimeFields(newVal) ?
                        (audit.name === "Measure Time" || audit.name === "Picked Up Time"? moment.utc(newVal).local().format("HH:mm:ss") :
                         moment.utc(newVal).local().format("MM/DD/YYYY")) : newVal) : newVal;
                        k["DateTime"] = newItem.created;
                        k["By"] = newItem.username;
                        if(k["Column"] === 'Patient Name')
                            this.patientAuditData.push(k);
                        else if(k["Column"] === 'Prescriber Name')
                            this.prescriberAuditData.push(k);
                        else if(k["Column"] === 'Drug Name')
                            this.drugAuditData.push(k);
                    });
                }
            });

        }

    }
    ngAfterViewInit() {
        // this.html2pngConvert()
    }
    patchRxValuesForRxAutoGenerateDoc(rxInfo) {
        this.rxDetailsViewModel  = new ERxDetailsViewModel();
        if(rxInfo && (!rxInfo.erxDetailsInRx || (rxInfo.erxDetailsInRx && !rxInfo.erxDetailsInRx.erxId))) {
            const PATIENT = rxInfo['Patient'];
            const PRESCRIBER = rxInfo['Prescriber'];
            const DRUG = rxInfo['Drug'];
            const PRESCRIPTION = rxInfo['Prescription'];
            const PRESCREFILL = rxInfo['PrescReFill'];
            //Patient Patching for annotation Document
            this.rxDetailsViewModel['IsNormalRx'] = true ;
            if(PATIENT) {
                this.rxDetailsViewModel['PatName'] = PATIENT['lastname'] + " " + PATIENT['firstname'];
                this.rxDetailsViewModel['PatBirthDt'] = PATIENT['dob'];
                this.rxDetailsViewModel['PayerId'] = null;
                this.rxDetailsViewModel['PatGender'] = PATIENT['gender'];
                this.rxDetailsViewModel['PatPhone'] = PATIENT['telephone'] ;
                this.rxDetailsViewModel['diagnosis'] = PATIENT['Diagnosis'] && PATIENT['Diagnosis'].length ? PATIENT['Diagnosis'].join(',') : null;
                this.rxDetailsViewModel['PatAddress'] =  (PATIENT['address1'] ? PATIENT['address1']+', ' : '')   + 
                (PATIENT['address2'] ? PATIENT['address2']+ ", " : '')  +
                (PATIENT['city']? PATIENT['city']+", " : '')  +  (PATIENT['state'] ? PATIENT['state']+", " : '')  +  (PATIENT['zipcode'] ? PATIENT['zipcode'] : '') ;
            }
            // //Prescriber Patching for annotation Document
            if(PRESCRIBER) {
               this.rxDetailsViewModel['PrescName'] = PRESCRIBER['prescriberlastname'] + " " + PRESCRIBER['prescriberfirstname'];
               this.rxDetailsViewModel['PrescSpi'] = PRESCRIBER['prescriberspinum'];
               this.rxDetailsViewModel['ClinicName'] = null;
               this.rxDetailsViewModel['PrescNpi'] = PRESCRIBER['prescribernpinum'];
               this.rxDetailsViewModel['PrescPhone'] = PRESCRIBER['telephone'];
               this.rxDetailsViewModel['PrescDea'] = PRESCRIBER['prescriberdeanum'];
               if(PRESCRIBER && PRESCRIBER && PRESCRIBER['PrescriberAddresses']) {
                const primaryAdd = PRESCRIBER['PrescriberAddresses'].find((item: any) => {
                    return (item['Id'] === PRESCRIPTION['PrescriberAddressId']);
                   });
                    if(primaryAdd){
                        this.rxDetailsViewModel['PrescAddress'] = (primaryAdd['AddressLine1']? primaryAdd['AddressLine1']: ' ' ) + (primaryAdd['AddressLine2']? ', '+primaryAdd['AddressLine2']: ' ') + (primaryAdd['CityName'] ? ', '+primaryAdd['CityName'] : ' ') + (primaryAdd['StateName'] ? ', '+primaryAdd['StateName']:' ') + (primaryAdd['ZipCode'] ? ', '+primaryAdd['ZipCode']:' ');
                } 
               }
              
            }
            // //Drug Patching for annotation Document
            if(DRUG) {
                this.rxDetailsViewModel['DrugName'] = DRUG['drugname'];   
                this.rxDetailsViewModel['DrugNdc'] = DRUG['ndc'];   
                this.rxDetailsViewModel['DrugStrength'] = DRUG['strength'];   
                this.rxDetailsViewModel['DrugForm'] = DRUG['drugformname'];   
                this.rxDetailsViewModel['DrugPotency'] =  DRUG['DrugPotency'];   
            }
            if(PRESCRIPTION && PRESCRIPTION) {
                this.rxDetailsViewModel['RxNum'] =  PRESCRIPTION['PrescNum'];   
            }
            if(PRESCREFILL) {
                this.rxDetailsViewModel['RefillNum'] =  PRESCRIPTION['ReFillNum'];   
                this.rxDetailsViewModel['RxDays'] =  PRESCREFILL['OrderDays'];   
                this.rxDetailsViewModel['rxOrdDt'] =  PRESCREFILL['OrderDtTm'] ? moment(PRESCREFILL['OrderDtTm']).format(MsgConfig.DATE_FORMAT) : '';   
                this.rxDetailsViewModel['RxQty'] =  PRESCREFILL['OrdQty'];   
                this.rxDetailsViewModel['prescRefillId'] =  PRESCREFILL['Id'] ?  
                PRESCREFILL['Id']  : '';    
            }
            if(rxInfo && rxInfo['SigCodes']) {
                this.rxDetailsViewModel['RxSig'] = rxInfo['SigCodes']['Description'];  
            }
            const systemData = this.systemData$["source"]["value"];
            this.rxDetailsViewModel['RxOriginName'] = systemData?.rxOriginsData.find((item: any)=> {
                return (item.Id === PRESCREFILL['RxOriginId']);
            })?.Name;   
            this.rxDetailsViewModel['RxMsg'] = null;  
            this.rxDetailsViewModel['prescRefillId'] = PRESCREFILL['Id'];  
            this.rxDetailsViewModel['RxDaw'] = PRESCREFILL['DAWId'] ? PRESCREFILL['DAWId'] : null; 
            this.detailsInfo = null;
            this.detailsInfo = this.rxDetailsViewModel;
            if (this.detailsInfo && this.detailsInfo.RxOrdDt) {
                this.rxOrdDt = moment.utc(this.detailsInfo.RxOrdDt).local().format("MM/DD/YYYY");
            }
            if (this.detailsInfo && this.detailsInfo.EffectiveDate) {
                this.EfctDt = moment.utc(this.detailsInfo.EffectiveDate).local().format("MM/DD/YYYY");
            }
            if (this.detailsInfo && this.detailsInfo?.RefillNum != null ){
                this.detailsInfo.auth = this.detailsInfo && this.detailsInfo?.MsgType === "RxRenewalResponse" && +(this.detailsInfo?.RefillNum) > 0 ? (+(this.detailsInfo?.RefillNum) - 1) : +(this.detailsInfo?.RefillNum) ;
                this.detailsInfo.totalDispensings = this.detailsInfo?.auth ? (+(this.detailsInfo?.auth) + 1) : 1;
            } 
        }
    }

    getNotes() {
        if(!this.IsFromQuickVerification && (((this.rxInfo && (this.rxInfo['rxType'] === 'er' || this.rxInfo['rxType'] === 'rf'))|| this.rxInfoFrmVrf ||  this.IsFromRoute)))
        {
            this.searchParams = {
                SearchText: null,
                SearchBy: null,
                OrderByColumn: null,
                SortDirection: null,
                PageNumber: this.paginationModel.pageNumber,
                PageSize: this.paginationModel.pageSize,
                PrescrefillId: null
            };
            this._notesLogSvc
                .getNotesLog(
                    "RxAnnotation",
                    this.rxInfo?.Prescription?.Id ? this.rxInfo.Prescription.Id : ((this.rxInfoFrmVrf)? this.rxInfoFrmVrf?.Prescription?.Id  : 0),
                    "1973-01-01",
                    moment(Date.now()).format("YYYY-MM-DD"),
                    this.searchParams
                )
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                        if(resp) {
                            this.NotesList = resp;
                            console.log( this.NotesList , " this.NotesList");
                        }
                });
        }
    }
    getNoteList(notesList) {
        if(notesList && notesList.length)
            this.NotesListSave = notesList;
    }
    getSubCategory(selectedSubcatId) {
            const selItem = this.NotesList?.NoteCat?.find((item: any) => { return item['Id'] ===  selectedSubcatId} );
            return selItem?.Name;
    }
    goToNotesPage() {
        const rxInfoLatestInfo = this._rxStore.rxFGLatestInfo$["source"]["value"];
        const PrescriptionID  = ((rxInfoLatestInfo && rxInfoLatestInfo["Prescription"])  ? rxInfoLatestInfo["Prescription"]["Id"] : (this.rxInfo) ? this.rxInfo["Prescription"]["Id"] : this.rxInfoFrmVrf["Prescription"]["Id"]);
        this.selectedRxItem = this.rxInfoFrmVrf ? this.rxInfoFrmVrf : this.rxInfo;
        this.selectedRxItem['Prescription']['Id'] = PrescriptionID;
        const modelRef = this._modalService.open(NotesLogComponent, {
            backdrop: false,
            keyboard: false, windowClass: "max-modal-79"
        });
        modelRef.componentInstance.okButton = true;
        modelRef.componentInstance.NotesListForRxAnnotation = this.NotesList;
        modelRef.componentInstance.OpenAsModal = true;
        modelRef.componentInstance.RxInfo = this.selectedRxItem;
        modelRef.componentInstance.IsFromRxSave =  this.IsNeweRx;
        modelRef.componentInstance.NotesListBeforeSave = this.rxInfo?.RxNotes;
        modelRef.componentInstance.NotesFr = "RxAnnotation";
        modelRef.componentInstance.CloseNotesValue.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if(modelRef)
                modelRef.close();
            this.getNotes();
        });
        modelRef.componentInstance.CloseNotesValueWithList.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if(resp?.isPopupCLose  && modelRef)
                modelRef.close();
            if( resp?.NotesAvailability) {
                this.getNotes();
            }
        });
        modelRef.componentInstance.CloseNotesValueList.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
            if(modelRef)
                modelRef.close();
            if(resp) {
                this.rxInfo["RxNotes"] = resp.Note;
                this.NotesList = resp;
                this.GetRxNotesList.emit(resp);
            }
   });
        
    }

    html2pngConvert() { 
        const data: HTMLElement | null = document.getElementById('pdfDownload');     
        if (data) { 
          html2canvas(data, { useCORS: false }).then((canvas) => { 
            const contentDataURL = canvas.toDataURL('image/png');
            sessionStorage.setItem('erxImg',contentDataURL) 
          });
        }
      }
}
