import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { PrivMaskGuard } from "src/app/guards";
import { AlertService, CommonService, DrugPickVerificationService, PatPreDrugModalService, ReportsService } from "src/app/services";
import { PrintService } from "src/app/services/print.service";
import { ColorCode, NRxUtils, PatchRXFGUtil, RxUtils, ValidateFormFieldsUtils, WijimoUtil } from "src/app/utils";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { CollectionView } from "@grapecity/wijmo";
import { AccCategoryE, PaginationModel, RxE, RxModel } from "src/app/models";
import { saveAs } from "file-saver";
import { CommonUtil } from "../../../utils";
import { HoverOver } from "src/app/utils/hover-over.util";
import { ColorCodeRx } from "src/app/utils/rx-color.util";
import { CommonDrugpickVerfHistComponent } from "../../shared/common-drugpick-verf-hist/common-drugpick-verf-hist.component";
import { MsgConfig } from "src/app/app.messages";
import { Verificationsenum } from "src/app/models/Verifications.enum";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormatsUtil } from "src/app/utils/formats.util";

@Component({
    selector: "app-review-drug-pick",
    templateUrl: "./review-drug-pick.component.html"
})
export class ReviewDrugPickComponent implements OnInit {
    today: string;
    maxDate: { year: number; month: number; day: number; };
    filterFG: any;
    dphVerifWJ: CollectionView;
    totalVerifRecs: any;
    dphVerifFlex: any;
    openOverride: boolean;
    privType: any;
    categoryId: any;
    overrideEndpoint: any;
    rxInfo: any;
    drugpickReport:any;
    totalVerifData: any;
    selectedRx: any;
    showVerifHist: any;
    fromDate: string;
    dphVerifHistory: any[] = [];
    totalCount: number;
    paginationModel: PaginationModel = new PaginationModel();
    pageNumber = this.paginationModel.pageNumber;
    pageSize = this.paginationModel.pageSize;
    startIndex: number;
    isDataExists = true;
    collectionChanged = false;
    @ViewChild("dphVerifHistTmp", { static: true })
    dphVerifHistTmp: any;
    dphVerifModif: {}[];
    wjHeaders: any;
    wjHeaders2: any;
    totalCountData: number;
    clearSearchData : string;
    unsubscribe$: Subject<void> = new Subject();

    constructor(
        private _fb: FormBuilder,
        private _reportsServ: ReportsService,
        private _modalSvc: NgbModal,
        private _nrxUtils: NRxUtils,
        private _comnSvc: CommonService,
        private _privMaskGuard: PrivMaskGuard,
        private _alertSer: AlertService,
        private _router: Router,
        private _patchRx: PatchRXFGUtil,
        private _printServ: PrintService,
        private _formUtils: ValidateFormFieldsUtils,
        private _rxUtils: RxUtils,
        private _dphVerifyServ: DrugPickVerificationService,
        private _colorCode: ColorCode,
        private _patPreDrugService: PatPreDrugModalService,
        private _wijimoUtils: WijimoUtil,
        private _commonUtil: CommonUtil,
        private _hoverOver: HoverOver,
        private _formatsUtil:FormatsUtil,
        private _colorCodeRx: ColorCodeRx,
        private _cdr : ChangeDetectorRef
    ) {
        this.today = moment().format("MM/DD/YYYY");
        this.fromDate = moment().subtract(1, "days").format("MM/DD/YYYY");
        this.maxDate = {
            year: moment(this.today).year(),
            month: moment(this.today).month() + 1,
            day: moment(this.today).date()
        };
        this.createFG();
    }

    ngOnInit() {
        this.collectionChanged = false;
        this.getRecords();
        this.patchDefaultValHeaders();
        this._wijimoUtils.wjSavedData$.pipe(takeUntil(this.unsubscribe$)).subscribe(w => {
            if (w && w["WijmoKey"] && w["WijmoKey"] === "reviewdrugVerfWJ") {
                this.patchDefaultValHeaders();
            }
        });
    }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("reviewdrugVerfWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("reviewdrugVerfWJ", storedWJ);
        const storedWJ2 = await this._wijimoUtils.getWJSavedData(
            "revHistoryListWj"
        );
        this.wjHeaders2 = this._wijimoUtils.patchDefHeader(
            "revHistoryListWj",
            storedWJ2);
    }

    createFG() {
        this.filterFG = this._fb.group({
            fromDate: [this.fromDate],
            toDate: [this.today],
            isCurrentUser: [false],
            verifyStatus: [],
            IsExport: null,
            RxNum: null,
            PageNumber: this.paginationModel.pageNumber,
            PageSize: this.paginationModel.pageSize,
            RefillNum: null ,
            PartialFillNum: null
        });
    }

    getRxSearch() {
        this.pageNumber = 0;
        this.filterFG.controls["PageNumber"].setValue(this.pageNumber);
        this.setPage(1);
        // this.getRecords();
    }

    setPage(page: number) {
        this.pageNumber = page;
        this.startIndex = (this.pageSize * (this.pageNumber - 1));
        this.filterFG.controls["PageNumber"].setValue(this.startIndex);
        this.getRecords();
    }

    setSize(size: any) {
        this.pageNumber = this.paginationModel.pageNumber;
        this.pageSize = size ;
        this.filterFG.controls["PageSize"].setValue(this.pageSize);
        this.setPage(this.paginationModel.pageNumber);
        // this.getRecords();
    }

    getStatusSelected() {
        this.pageNumber = 0;
        this.filterFG.controls["PageNumber"].setValue(this.pageNumber);
        this.filterFG.controls["PageSize"].setValue(this.pageSize);
        this.setPage(this.paginationModel.pageNumber);
        // this.getRecords();
    }

    async getRecords() {
        await this._formUtils.blurAndDelay();
        const resp = this._formUtils.checkDateErrors(this.filterFG, "fromDate", "toDate", 0);
        if (resp === "DATEISSUE") {
            this._alertSer.error(MsgConfig.DATE_RANGE_ERROR);
        } else if (resp === "INVALID") {
            this._alertSer.error(MsgConfig.INVALID_DATES_ERROR);
        } else {
            if ((typeof this.filterFG.value["RxNum"]) === "string") {
                const rxNum = this.filterFG.value["RxNum"].split("-");
                if (rxNum && rxNum.length) {
                    this.filterFG.value["RxNum"] = (rxNum && rxNum[0]) ? parseInt(rxNum[0], 0) : null;
                    this.filterFG.value["RefillNum"] = (rxNum && rxNum[1]) ? parseInt(rxNum[1], 0) : null;
                } if (rxNum && rxNum.length === 3) {
                    this.filterFG.value["PartialFillNum"] = (rxNum && rxNum[2]) ? parseInt(rxNum[2], 0) : null;
                }
            }
            this._dphVerifyServ.getDphVerifHistory(this.filterFG.value).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
                if (resp) {
                    this.totalVerifData = resp;
                    if (resp && resp.data && resp.data.length) {
                        this.totalVerifRecs = resp.data;
                        this.totalCount = resp.TotalRecords;
                        this.isDataExists = true;
                    } else {
                        this.totalVerifRecs = [];
                        this.isDataExists = false;
                        this.totalCount  = 0;
                        this.dphVerifWJ = new CollectionView(null)
                    }
                    this.generateDphVerifWJ();
                }
            });
        }
        
    }


    generateDphVerifWJ() {
        const mapDataSet = [];
        if(this.totalVerifRecs?.length) {
            this.totalVerifRecs.map((patRx, i) => {
                const k = {};
                k["RowId"] = i;
                k["data"] = patRx;
                k["User"] = patRx.PrescReFill.RegPharmacist ? patRx.PrescReFill.RegPharmacist : "";
                let dphVerify = patRx["drugPickVerif"];
                if (dphVerify && dphVerify.length > 0) {
                const  drugPickVerificationStatus = patRx.drugPickVerificationStatus;
                    dphVerify = dphVerify.filter((ele: any) => {
                        return ((ele.Result + "").trim() === drugPickVerificationStatus ? patRx.drugPickVerificationStatus.trim() : null );
                    });
                }
                k["Tech"] = patRx.PrescReFill.TechInitials ? patRx.PrescReFill.TechInitials : "";
                let FillDtTm;
                if (patRx && patRx.PrescReFill && patRx.PrescReFill.FillDtTm) {
                    FillDtTm = moment.utc(patRx.PrescReFill.FillDtTm).local().format(
                        "MM/DD/YYYY"
                    );
                }
                k["Verified"] = (dphVerify && dphVerify.length > 0 && (dphVerify[dphVerify.length - 1]["Result"].trim().toLowerCase() === "p" || dphVerify[dphVerify.length - 1]["Result"].trim().toLowerCase() === "f" || dphVerify[dphVerify.length - 1]["Result"].trim().toLowerCase() === "rv") ) ? moment.utc(dphVerify[dphVerify.length -1].VerifDtTm).local() : null;
                k["Bottle NDC"] = this._formatsUtil.getNDCFormat(patRx.Drug.ndc);
                if (patRx && patRx.drugPickVerificationStatus) {
                    if ((patRx.drugPickVerificationStatus).trim().toLowerCase() === "rv") {
                        k["Result"] =  "Re-Verify";
                    }
                    if ((patRx.drugPickVerificationStatus).trim().toLowerCase() === "p") {
                        k["Result"] = dphVerify && dphVerify.length > 0 && (dphVerify[dphVerify.length - 1].IsReverified) ? Verificationsenum.reverified : Verificationsenum.verified;
                    }
                    if ((patRx.drugPickVerificationStatus).trim().toLowerCase() === "f") {
                        k["Result"] =  "FAIL";
                    }
                    if ((patRx.drugPickVerificationStatus).trim().toLowerCase() === "n" || (patRx.drugPickVerificationStatus).trim().toLowerCase() === "nv") {
                        k["Result"] =  "NOT YET VERIFIED";
                    }
                } else {
                    k["Result"] =  "NOT YET VERIFIED";
                }
                k["Rx#"] = patRx.Prescription.PrescNum.toString();
                k["Ref#"] = patRx.PrescReFill.ReFillNum;
                k["Fill#"] = this._comnSvc.checkIsPartialFill(patRx.PrescReFill, patRx.Prescription) ? patRx.PrescReFill.PartialFillNo : " ";
                k["Drug Name"] = (patRx.Drug.drugname + " " + patRx.Drug.strength + " " + patRx.Drug.drugformname).toUpperCase();
                k["Drug Class"] = patRx.Drug.drugclass;
                k["DrugClass"] = patRx.Drug.drugclass;
                k["Filled"] = moment.utc(patRx.PrescReFill.FillDtTm).local().format(
                    "MM/DD/YYYY"
                );
                k["Tr Count"] = (patRx && patRx["drugPickVerif"] && patRx["drugPickVerif"].length > 0 ) ? patRx["drugPickVerif"].length : 0;
                k["Qty"] = patRx.PrescReFill.DispQty ? (patRx.PrescReFill.DispQty).toFixed(3) : "0.000";
                k["Qty. Owed"] = "";
                k["Verif Qty"] = (dphVerify && dphVerify.length) ? (patRx.PrescReFill.DispQty ? (patRx.PrescReFill.DispQty - (patRx.PrescReFill.OweQty ? patRx.PrescReFill.OweQty : 0)).toFixed(3) : "0.000") : "0.000";
                k["Status"] =
                (patRx.PrescReFill.StatusId === 4 || patRx.Prescription.RxStatusId === 4) ? "T" :
                    (patRx.PrescReFill.StatusId === 2 || patRx.Prescription.RxStatusId === 2 || patRx.RxBill.StatusId === 6) ? "F" :
                        (patRx.PrescReFill.StatusId === 3
                            || patRx.Prescription.RxStatusId === 3) && patRx.RxBill.StatusId === 2 ? "BI" :
                            (patRx.RxBill.StatusId === 2 && patRx.PrescReFill.StatusId !== 4
                                && patRx.Prescription.RxStatusId !== 2) ? "B" :
                                ((patRx.PrescReFill.StatusId === 3 || patRx.Prescription.RxStatusId === 3) &&
                                    patRx.RxBill.StatusId === 1) ? "UI" : "U";
                k["PrescRefillId"] = patRx.PrescReFill.Id;
                k["IsPicked"] = patRx.PickupRx && patRx.PickupRx.IsPickedUp ? true : false;
                k["DrugHoverOver"] = this._hoverOver.getDrugNameHoverOver(k);
                const rowId = k["RowId"] >= 0 ? k["RowId"] : "";
                const RxDetails = rowId >= 0 ?   this.totalVerifRecs[rowId] : null;
                k["RxHoverOver"] = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx", "hoverOver");
                // return k;
                mapDataSet.push(k)
            })
            this.dphVerifWJ = new CollectionView(mapDataSet,
                {
                    sortComparer: (a: any, b: any) => {
                        return moment(a).isValid() && moment(b).isValid()
                            ? moment(a).diff(moment(b)) : null;
                    },
                    collectionChanged: (s: CollectionView) => {
                        this.totalCountData = this.collectionChanged ? s.totalItemCount : this.totalCount;
                        this.isDataExists = this.totalCountData ? true : false;
                    }
                });
            } else {
                this.isDataExists = false;
                this.dphVerifWJ = new CollectionView(null)
            }
    }
    searchGridData(event){
        this.collectionChanged = event && event.target && event.target.value ? true : false;
        if(event.target.classList[0] === "wj-btn"){
            this.totalCountData = this.totalCount;
        }
    }
    openVerifHistory(item) {
        if (item && item.data) {
            this.dphVerifHistory = item.data.drugPickVerif ? [...item.data.drugPickVerif] : [];
            if (this.dphVerifHistory &&  this.dphVerifHistory.length)  {
                this.dphVerifHistory = this.dphVerifHistory.sort((a, b) => {
                    return <any>new Date(b.VerifDtTm) - <any>new Date(a.VerifDtTm);
                });
            this.dphVerifHistory.map(elm => {
                elm["PrescNum"] = item["Rx#"];
                elm["RefillNum"] = item["Ref#"];
                elm["PartialfillNum"] = item["Fill#"];
            });
        }
            const modelRef = this._modalSvc.open(CommonDrugpickVerfHistComponent,
                { backdrop: "static", size: "lg", centered: true });
            modelRef.componentInstance.DPVVerfHist = this.dphVerifHistory;
            modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$))
                .subscribe(resp => {
                    modelRef.close();
            });
        //    this.generateDphHistWijmo();
        }
            // this._modalSvc.open(this.dphVerifHistTmp, { backdrop: "static", size: "lg", centered: true });
    }

    generateDphHistWijmo() {
        this.dphVerifModif = this.dphVerifHistory.map(patRx => {
            const obj = {};
            obj["Rx#"] = patRx.PrescNum;
            obj["Ref#"] = patRx.RefillNum;
            obj["Fill#"] = patRx.PartialfillNum;
            obj["User Initial"] = patRx.UserName;
            obj["Date Time Verified"] = patRx.VerifDtTm;
            obj["Bottle NDC"] = patRx.BottleNDC;
            obj["Result"] = patRx.Result ? patRx.Result.trim() : "";
            return obj;
        });
    }

    OnDoubleClickOnWijimo(s: any, e: any) {
        const ht = s.hitTest(e);
        if (s.selectedRows[0].dataItem && ht.cellType === 1) {
            this.routeToEditRx(s.selectedRows[0].dataItem);
        }
    }

    routeToEditRx(item: any) {
        this.selectedRx = item;
        if (this._privMaskGuard.canActivate(AccCategoryE.Rx, RxE.EditRx)) {
            this.routeToRx();
        } else {
            this.openOveride("EditRx", "PUT_Rx");
        }
    }

    OverrideRes(val) {
        this.openOverride = false;
        if (val) {
            this.routeToRx();
        }
    }

    openOveride(content, endpoint?) {
        this.privType = content;
        this.categoryId = content;
        this.openOverride = true;
        this.overrideEndpoint = endpoint;
    }


    async routeToRx() {
        let rxInfo: any = await this._nrxUtils.getandStoreRxInfo(+this.selectedRx["Rx#"], this.selectedRx["PrescRefillId"], false, null, null, null, true,null,null,null,true);
        if (rxInfo) {
            rxInfo = this._nrxUtils.editRxPatchValues(rxInfo);
            this._nrxUtils.createPatchStoreRxFG(rxInfo);
            this._nrxUtils.getCommonEndPoints(rxInfo);
            this._patPreDrugService._patPreDrugModal$.next("Rx");
            const modalRef = await this._patPreDrugService.modalInstanceRef;
            modalRef.componentInstance.CloseEditMode.pipe(takeUntil(this.unsubscribe$))
                .subscribe(x => {
                    modalRef.close();
                    this.selectedRx = null;
                    this._patPreDrugService._patPreDrugModal$.next(null);
                    this.getRecords();
                });
            modalRef.componentInstance.CloseAndReopen.pipe(takeUntil(this.unsubscribe$))
                .subscribe(d => {
                    modalRef.close();
                    if (d) {
                        this.selectedRx["Rx#"] = d["rxNo"];
                        this.selectedRx["PrescRefillId"] = d["rfId"];
                        this.routeToRx();
                    } else {
                        this.selectedRx = null;
                        this._patPreDrugService._patPreDrugModal$.next(null);
                        this.getRecords();
                    }
                });
        }
    }


    getReport(IsExport : boolean = false) {
        this.filterFG.controls["IsExport"].patchValue(IsExport);
        // this.filterFG.value["IsExport"] = IsExport;
        // const dataInfo = this.filterFG.value;
        this._reportsServ.getDphVerifReport(this.filterFG.value).pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            if (resp && resp.size > 0 && resp.type === "application/pdf") {
                this.printReport(resp);
            } else if (resp && resp.size > 0 && resp.type === "application/ms-excel") {
                this.drugpickReport = resp;
                       const blob = new Blob([resp], { type : "application/ms-excel" });
                        const file = new File([blob], "Review drugpick " + ".xlsx", { type: "application/ms-excel" });
                       saveAs(file);

            } else {
                this._alertSer.error("Report is unavailable.");
            }
        })
    }

    printReport(report) {
        const newBlob = new Blob([report], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(newBlob);
        reader.onloadend = function () {
            const base64data = reader.result;
            this._printServ.printPdf(base64data);
        }.bind(this);
    }

    init(flex: wjcGrid.FlexGrid) {
        this.dphVerifFlex = flex;
        flex.focus();
        flex.columnHeaders.rows[0].wordWrap = true;
        flex.autoSizeRow(0, true);
        // this.selectRadioOnKeyEvents(flex);
    }

    itemFormatter = (panel, r, c, cell) => {
        const row = panel.rows[r];

        if (row.dataItem) {
            cell.style.background = "";
            if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Drug Name") {
                const colors = this._colorCode.showDrugColorRefactoring(row.dataItem);
                if(colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
            } else if (panel.cellType === wjcGrid.CellType.Cell && panel.columns[c].binding === "Rx#") {
                const rowId = row && row.dataItem ? row.dataItem["RowId"] : "";
                const RxDetails = rowId >= 0 ? this.totalVerifRecs[rowId] : null;
                const colors = this._colorCodeRx.showRxColorCodeAndHoverOver(RxDetails, "RxObject", "Rx");
                if (colors && colors.bg && colors.color) {
                    cell.style.background = colors.bg;
                    cell.style.color = colors.color;
                } else {
                    cell.style.background = "#FFF";
                    cell.style.color = "#000";
                }
                cell.style.fontWeight = "bold";
                cell.style.paddingLeft = "1rem";
            } else {
                cell.style.backgroundColor = "#FFF";
                cell.style.color = "#000";
                cell.style.fontWeight = "normal";
                cell.style.textAlign = "initial";
            }
        }
    }

    clearSearchInputs() {
        this.collectionChanged = false;
        this.clearSearchData = null;
        this._cdr.detectChanges();
        this.clearSearchData = '';
        this.isDataExists = false;
        this.filterFG.reset();
        this.filterFG.markAsUntouched();
        this.filterFG.patchValue({
            fromDate: this.fromDate,
            toDate: this.today,
        });
        this.setSize(this.paginationModel.pageSize);
    }

    focusToFirst(event) {
        if (document.getElementById("DphFromDate")) {
            event.preventDefault();
            this._cdr.detectChanges();
            document.getElementById("DphFromDate").focus();
        }
    }

    focusToFirstIfEmpty(event) {
        this.focusToFirst(event);
    }
    toggleColorLegend() {
        this._alertSer.showHelpText({RxStatus: true, DrugName: true, Status: false});
    }
    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
