import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsgConfig } from 'src/app/app.messages';
import { SystemData } from 'src/app/models';
import { VaccineImmunization } from 'src/app/models/rx.model';
import { AlertService, CommonService, EditDrugService, FilesService, InsuranceService, PatientService, RxService, UserService } from 'src/app/services';
import { DrugStore, RxStore } from 'src/app/store';
import { DrugUtils } from 'src/app/utils';
import { FormatsUtil } from 'src/app/utils/formats.util';
import { DrugRefreshMmsComponent } from '../../drug/drug-common-header/drug-refresh-mms/drug-refresh-mms.component';
import { CommonWarnorconfirmPopupComponent } from '../common-warnorconfirm-popup/common-warnorconfirm-popup.component';

@Component({
  selector: 'app-vaccine-immunization',
  templateUrl: './vaccine-immunization.component.html',
  styleUrls: ['./vaccine-immunization.component.scss']
})
export class VaccineImmunizationComponent implements OnInit, OnDestroy {
  vaccImmuneFG: FormGroup;
  modelRef: any;
  unsubscribe$: Subject<void> = new Subject();
  systemData: SystemData;
  @ViewChild("VaccineForm", {static: true})
  VaccineForm: any;
  @ViewChild("diagList", { static: true })
  diagList: any;
  @Input() rxFG: any;
  @Input() rxInfo: any;
  @Input() frmVerf: boolean;
  @Input() miscInfo: any;
  @Input() frmButton: boolean = false;
  @Input() frmQuickRef: boolean = false;
  @Input() frmFileGenerate: boolean = false;
  @Input() completionIdFrmFile: any;
  @Output()IsPopUpClosed = new EventEmitter();
  vaccineResp: any;
  vaccDrpDwnInfo: any;
  morePatInfo: any;
  appUsrData: any;
  npi: any;
  drugInfo: any;
  costBasisDrpDown: any;
  durData= [];
  disableMinorcsnt: boolean;
  loggedUserId: any;
  formGrpInvld: boolean;
  eventRaised: any;
  isDisabledEXP: boolean;
  isExpDtTmUpdated: boolean;
  hasStopLotExp: any;
  finalLotList: any;
  druExpFromBarcode: any;
  minDate: { year: number; month: number; day: number; };
  BillingUnitDropDowns: any[] = [];
  drugInputValue: any;
  NDCScaned: any;
  drugFG: any;
  unsubsrcibe$: Subject<void> = new Subject();
  modelRefVaccn: any;
  patDiagnis: any;
  diagnosisList: any;
  isRequired: boolean;
  constructor(private _fb: FormBuilder, 
    private _mdlService: NgbModal, 
    private _cmnServ: CommonService,
     private _patientService: PatientService, 
     private _rxStore: RxStore, 
     private _insuSvc: InsuranceService, 
     private _alrtSvc: AlertService, 
     private _rxServ: RxService, 
     private _userService: UserService, 
     public _formatsUtil:FormatsUtil, 
     private _cdr: ChangeDetectorRef, 
     private _drugUtils: DrugUtils, 
     private _drugStore: DrugStore,
    private _patientSVC: PatientService,
    private _drgWar: FilesService,
    private edtDrgServ: EditDrugService) {
    this.createFG();
    const today = moment().format("MM/DD/YYYY");
    this.minDate = {
          year: moment(today).year(),
          month: moment(today).month() + 1,
          day: moment(today).date(),
    };
  }

  ngOnInit(): void {
    this.systemData = this._cmnServ.systemData$["source"]["value"];
    this.appUsrData = this._cmnServ.appUserData$["source"]["value"];
    const userId = this._userService.getToken("UserId");
    const userIdInt = parseInt(userId);
    this.loggedUserId = this.appUsrData.find((item: any) => item.AppUserId === userIdInt);
    this.drugInfo = this._rxStore.edtDrugInfo$["source"]["value"];
    this.costBasisDrpDown = this._insuSvc.dropdownData$["source"]["value"];
    this.durData = (this.rxInfo?.rxType !== "er" && !this.frmVerf && !this.frmFileGenerate) ? ((this.rxInfo?.rxType === "rf" || this.frmQuickRef) ? this.getDurValues() : this._rxStore.addDur$["source"]["value"]) : this._rxStore.storeDurNewRxFlow(null);;
    this.npi = this._cmnServ.getSetttingValue("TenantSettings", "TNT_NPI_NO") ?
    (this._cmnServ.getSetttingValue("TenantSettings", "TNT_NPI_NO")).trim() : "";
    this.getDrugLots();
    
    
  }
  get PRESCREFILL(): FormGroup {
    return this.rxFG?.get("PrescReFill") as FormGroup;
  }
  get DRUG(): FormGroup {
    return this.rxFG?.get("Drug") as FormGroup;
  }

  getDurValues() {
    const PrescriptionId = this.rxFG && this.rxFG.controls['Prescription'] && this.rxFG.controls['Prescription'].value['Id'] ? this.rxFG.controls['Prescription'].value['Id'] : null;
    this._rxServ.getDurValues(PrescriptionId,null)
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe((resp)=>{
                        const  durDataNewRxFlow = [];
                    if (resp && resp.length) {
                        resp.map(item => {
                            if (item && !item.IsRefillSpecific) {
                            durDataNewRxFlow.push(item);
                            }
                        });  
                        if (durDataNewRxFlow && durDataNewRxFlow.length) {
                            this._rxStore.storeDurNewRxFlow(durDataNewRxFlow);
                            this.durData = this._rxStore.addDur$["source"]["value"];
                        }
                    }
                  });
  }

  async getVaccineInfo() {
    this.vaccImmuneFG.controls["UserId"].patchValue(this.loggedUserId?.AppUserId);
    this.vaccImmuneFG.controls["PatientConsentId"].patchValue(1);
    this.vaccImmuneFG.controls["DoseNum"].patchValue(1);
    const length = this.durData && this.durData.length > 0 ? this.durData.length : 0;
    const moreInfoData = await this._patientService.getMorepatientInfo(this.rxInfo["Patient"]["patientid"]).toPromise();
    this.morePatInfo = moreInfoData?.MorePatientInfo ? moreInfoData.MorePatientInfo : null;
    const dob = moment(this.rxInfo["Patient"]["dob"]);
    const years = moment().diff(dob, "year");
    this.disableMinorcsnt = years > 18 ? true : false;
    this.vaccImmuneFG.controls["VFCEligibilityId"].patchValue(years > 19 ? 1 : null)
    if (this.morePatInfo)
        this.patchPatientDetails();
    this.patchBillingInfo();
    this.patchLotNUnits();
    this.patchDefDiagnosis();
    const data = {
      PrescRefillId: (this.rxInfo.rxType === "er" || this.frmButton || this.frmVerf) ? this.rxInfo["PrescReFill"]["Id"] : null,
      RxId: (this.rxInfo.rxType === "er" || this.frmButton || this.frmVerf) ? this.rxInfo["Prescription"]["Id"] : null,
      PatientId: this.rxInfo["Patient"]["patientid"],
      DrugNdc: this.rxInfo["Drug"]["ndc"]
    }
    this._cmnServ.getVaccineImmunizationInfo(data).pipe(takeUntil(this.unsubscribe$)).subscribe(async (resp: any) => {
      if (resp) {
        this.vaccineResp = resp;
        this.vaccDrpDwnInfo = this.vaccineResp.VaccineDropDown;
        this.vaccImmuneFG.controls["AdminProvSuffixId"].patchValue(6);
        this.vaccImmuneFG.controls["DrgManufactId"].patchValue(this.vaccineResp?.Manufacturer ? this.vaccineResp?.Manufacturer?.Id : null)
        if (this.vaccineResp?.DURDetails?.length) {
          if (this.rxInfo.rxType === "er" || this.frmVerf || this.frmFileGenerate) {
            this.durData = [];
            this.filterDurValues();
          } else {
            if (length) {
              this.filterDurValues();
            } else {
              this.durData = [this.vaccineResp.DURDetails[0]];
              this.durData =  _.uniqBy(this.durData, "Id");
              this._rxStore.storeDurNewRxFlow(this.durData);
            }
          }
        }
        if (this.rxInfo.rxType == "er" || this.frmVerf || this.frmFileGenerate) {
          this.vaccImmuneFG.patchValue(this.vaccineResp?.VaccineImmunization);
          if (this.frmFileGenerate) this.vaccImmuneFG.controls["CompletionStatusId"].patchValue(this.completionIdFrmFile);
          if (this.vaccImmuneFG.controls["CompletionStatusId"].value === 1 || this.vaccImmuneFG.controls["CompletionStatusId"].value === 4 ) {
            this.isRequired = true;
            const contrlName = ["PatientRaceId", "PatientEthnicityId", "DrgManufactId", "drugBillingUnits", "AdminSiteId", "UserId", "AdminProvSuffixId", "DoseNum"
              , "VISPresentDtTm", "VISPublishDtTm", "VFCEligibilityId", "FundingSrcId", "drugLot", "lotExpiryDate"];
              contrlName.map(val => {
                if (!this.vaccImmuneFG.controls[val].value) {
                  this.vaccImmuneFG.controls[val].markAsTouched();
                  this.vaccImmuneFG.controls[val].setValidators(Validators.required);
                  this.vaccImmuneFG.controls[val].markAsUntouched();
                  setTimeout(() => {
                      this.vaccImmuneFG.controls[val].markAsTouched();
                  });
                } else if (this.vaccImmuneFG.controls["lotExpiryDate"].value && (moment(this.minDate.month + "/" + this.minDate.day + "/" + this.minDate.year, "MM/DD/YYYY").isAfter(moment(this.vaccImmuneFG.controls["lotExpiryDate"].value, "MM/DD/YYYY")))) {
                  this.vaccImmuneFG.controls["lotExpiryDate"].markAsTouched();
                  this.vaccImmuneFG.controls["lotExpiryDate"].setErrors({ "minDate": true });
                  this.vaccImmuneFG.controls["lotExpiryDate"].markAsUntouched();
                  setTimeout(() => {
                      this.vaccImmuneFG.controls["lotExpiryDate"].markAsTouched();
                  });
                }
              });
          } else {
            this.isRequired = false;
          }
          if (this.miscInfo && this.miscInfo.rxExtra) {
            const subClarfId = this.miscInfo.rxExtra.SubClarifId1 ? this.miscInfo.rxExtra.SubClarifId1 : (this.miscInfo.rxExtra.SubClarifId2 ? this.miscInfo.rxExtra.SubClarifId2 : this.miscInfo.rxExtra.SubClarifId3);
            this.vaccImmuneFG.controls["SubClarifId"].patchValue(subClarfId);
          }
        }
        this.modelRefVaccn = this._mdlService.open(this.VaccineForm, {centered: true, backdrop:false, keyboard: false, size:"lg", windowClass:"max-modal-1400"})
      }
    });
    const oldIncValue = this.vaccImmuneFG.controls["Incentive"].value;
    this.vaccImmuneFG.controls["Incentive_Old"].patchValue(oldIncValue);
  }
  async patchDefDiagnosis() {
    const resp = await this._patientSVC.getPatientDiagnosis(this.rxFG.value["Patient"]["patientid"]).toPromise();
    this.patDiagnis = resp;
    if (!this.rxFG?.value?.PrescReFill?.ImmunizationId ||(this.rxInfo.rxType !== "er" && !this.frmVerf)) {
      const data = this._rxStore.edtDrugInfo$["source"]["value"]?.diagnosisInfo ? this._rxStore.edtDrugInfo$["source"]["value"] : await this.edtDrgServ.getDrugInfo(this.rxFG.value["Drug"]["id"]).toPromise();
      this.vaccImmuneFG.controls["DiagnosisId"].patchValue((this.rxInfo?.rxExtraInfo?.diagCode?.length && this.rxInfo?.rxExtraInfo.diagCode[0]?.DiagnosisId) ? this.rxInfo?.rxExtraInfo.diagCode[0]?.DiagnosisId : (this.miscInfo && this.miscInfo?.diagCode?.DiagnosisId ? this.miscInfo?.diagCode?.DiagnosisId: (data?.diagnosisInfo?.DIAGCODE)));
      if ((this.rxInfo?.rxExtraInfo.diagCode?.length && this.rxInfo?.rxExtraInfo.diagCode[0]?.DiagnosisId) || (this.miscInfo && this.miscInfo?.diagCode && this.miscInfo?.diagCode?.DiagnosisId)) {
        const search = {
          Code: (this.rxInfo?.rxExtraInfo?.diagCode?.length ? this.rxInfo?.rxExtraInfo.diagCode[0]?.DiagnosisId : (this.miscInfo && this.miscInfo?.diagCode && this.miscInfo?.diagCode?.DiagnosisId)),
          Description: null,
          pageNum: 1,
          pageSize: 60
        };
        const respList: any = await this._drgWar.getDrugDiagnosis(search, false).toPromise();
        if (respList?.TempDiagnosis?.length) {
          this.patDiagnis.TempDiagnosis.push(respList?.TempDiagnosis[0])
        }
      } else if (data?.diagnosisInfo){
        this.patDiagnis.TempDiagnosis.push(data?.diagnosisInfo);
      }
    }
    this.diagnosisList = _.uniqBy(this.patDiagnis?.TempDiagnosis, "DIAGCODE");
      console.log(this.diagnosisList, "diagnosisList")
  }
  patchLotNUnits() {
    this.BillingUnitDropDowns = this.systemData.DrugUnit.filter(f => ["EA", "GM", "ML"].includes(f.Remarks));
        if (this.BillingUnitDropDowns && this.BillingUnitDropDowns.length) {
            this.BillingUnitDropDowns.map(v => {
                if (v["Remarks"] === "EA") {
                    v["Name"] = "EACH";
                } else if (v["Remarks"] === "GM") {
                    v["Name"] = "GRAMS";
                } else if (v["Remarks"] === "ML") {
                    v["Name"] = "Milliliters";
                }
            });
        }
    this.vaccImmuneFG.patchValue({
      drugLot: this.finalLotList?.length ? this.finalLotList[0]["LotNum"] : null,
      lotExpiryDate: this.finalLotList?.length ? moment.utc(this.finalLotList[0]["ExpDt"]).local().format("MM/DD/YYYY") : null,
      DrugLotId : this.finalLotList?.length ? this.finalLotList[0]["Id"] : null,
      drugBillingUnits: this.DRUG.controls["drugunitid"].value ? this.DRUG.controls["drugunitid"].value : null
    });
    // this.drugInputValue = (this.rxInfo?.Drug?.drugname.toUpperCase() + ' ' + (this.rxInfo?.Drug?.strength ? this.rxInfo?.Drug?.strength : ' ') + ' ' + this.rxInfo?.Drug?.drugformname);
    this.drugInputValue = (this.rxInfo?.Drug?.drugname.toUpperCase());
  }
  filterDurValues() {
    if (this.vaccineResp.DURDetails?.length > 1) {
      this.vaccineResp.DURDetails.map((item: any) => this.durData.push(item));
    } else {
      this.durData.push(this.vaccineResp.DURDetails[0]);
    }
    this.durData =  _.uniqBy(this.durData, "Id");
    this._rxStore.storeDurNewRxFlow(this.durData);
  }
  optedDrug(val) {
    this.drugInputValue = true;
    if (val) {
        if (val.id === this.rxInfo?.Drug?.id) {
          this.NDCScaned = val.ndc;
          this._cdr.detectChanges();
          // this.drugInputValue = (val?.drugname.toUpperCase() + ' ' + (val?.strength ? val?.strength : ' ') + ' ' + val?.drugformname)
          this.drugInputValue = (val?.drugname?.toUpperCase())
          this.vaccImmuneFG.patchValue({
            drugLot: val.LotNum,
            lotExpiryDate: val.ExpirationDate,
          });
        } else {
          this._alrtSvc.error("The scanned NDC is different from the billed NDC.")
          this.drugInputValue = "";
        }
        
    } else {
        this.NDCScaned = null;
        this.drugInputValue = "";
    }
}

  createFG() {
    this.vaccImmuneFG = this._fb.group(new VaccineImmunization());
  }

  async patchBillingInfo() {
    if (this.rxInfo) {
      this.vaccImmuneFG.controls["Incentive"].patchValue(this.rxInfo["RxOtherAmount"]["Incentive"])
      this.vaccImmuneFG.controls["CostBasisId"].patchValue(this.rxInfo["RxOtherAmount"]["CostBasisCatId"]);
      if (this.rxInfo.rxType === "rf" || this.frmQuickRef) {
        const resp = await this._rxServ.getRxExtraDefaultValues(this.rxFG.value["PrescReFill"]["Id"] ?
            this.rxFG.value["PrescReFill"]["Id"] : 0).toPromise();
        if (resp && resp.rxExtra) {
          const subClarfId = resp.rxExtra.SubClarifId1 ? resp.rxExtra.SubClarifId1 : null;
          this.vaccImmuneFG.controls["SubClarifId"].patchValue(subClarfId);
        }
      } else {
        this.vaccImmuneFG.controls["SubClarifId"].patchValue(this.rxInfo["SubClarifIdComb"]);
      }
      
    }
  }
  patchPatientDetails() {
    this.vaccImmuneFG.patchValue({
      GuardienLastName: this.morePatInfo.GuardienLastName,
      GuardienFirstName: this.morePatInfo.GuardienFirstName,
      MaidenMiddleName: this.morePatInfo.MaidenMiddleName,
      PatientRaceId: this.morePatInfo.PatientRaceId,
      PatientEthnicityId: this.morePatInfo.PatientEthnicityId,
      PatientComorbidityStatusId: this.morePatInfo.PatientComorbidityStatusId,
      PatientRelationId: this.morePatInfo.PatientRelationId
    })
  }

  closeModal(type?: any) {
    if (this.modelRefVaccn) {
      this.modelRefVaccn.close()
      this.modelRefVaccn = null;
      if (type === 'cancel')
        this._rxStore.storeVaccineImmuneDetails(null);
      this.IsPopUpClosed.emit(null);
    }
  }

  changeToNumber(val: string, prop: string) {
    this.vaccImmuneFG.controls[prop].setValue(val ? (parseFloat(val)).toFixed(2) : "0.00");
  }

  saveImmunizationForm(){
    if (this.vaccImmuneFG.valid) {
      this.formGrpInvld = false;
      const immuneDetails = this.vaccImmuneFG.value;
      this._rxStore.storeVaccineImmuneDetails(immuneDetails);
      if (!this.frmFileGenerate) {
        this.rxFG.controls["SubClarifIdComb"].patchValue(this.vaccImmuneFG.value.SubClarifId);
        const fg = this.rxFG.controls["RxOtherAmount"] as FormGroup;
        fg.controls["CostBasisCatId"].patchValue(this.vaccImmuneFG.value.CostBasisId);
        fg.controls["Incentive"].patchValue(this.vaccImmuneFG.value.Incentive);
      }
      if (this.rxInfo["rxType"] === "er" || this.frmVerf || this.frmFileGenerate) {
        const savedDet = this._rxStore.vacnImmune$["source"]["value"];
        savedDet.PatientId = this.rxInfo["Patient"]["patientid"];
        savedDet.PrescRefillId = this.rxInfo["PrescReFill"]["Id"];
        savedDet.PrescNum =  this.rxInfo["Prescription"]["PrescNum"];
        savedDet.RefillNum = this.rxInfo["PrescReFill"]["ReFillNum"];
        savedDet.FillNo = this.rxInfo["PrescReFill"]["PartialFillNo"];
        savedDet.BucketId = this.PRESCREFILL.value.BucketId;
        savedDet.DrugId = this.PRESCREFILL.value.DrugId;
        const payLoad = {
          VaccineImmunization: savedDet
        }
          this._cmnServ.updateVaccineImmunization(payLoad).subscribe((resp: any) => {
            if (resp) {
              this._rxStore.storeDurNewRxFlow(null);
              this._alrtSvc.success("Vaccine Immunization Details saved successfully.");
              if (this.frmFileGenerate) {
                this.IsPopUpClosed.emit(true);
              }
            }
          });
      }
      this.closeModal();
    } else {
      this.formGrpInvld = true;
      this._alrtSvc.error(MsgConfig.ENTER_REQ_FIELD);
    } 
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
}

  focusOnSave(event) {
  if (document.getElementById("SaveBtn")) {
    event.preventDefault();
    document.getElementById("SaveBtn").focus();
  }
  }
 enteredDrugLot(event: any, _fg?: any, fc?: string) {
  if (!this.eventRaised) {
      this.eventRaised = true;
      if (event?.value && fc==="LotNum") {
        this.vaccImmuneFG.controls["drugLot"].patchValue(event.value["LotNum"]);
        this.vaccImmuneFG.controls["DrugLotId"].patchValue(event.value?.Id);
        this.vaccImmuneFG.controls["lotExpiryDate"].patchValue(event.value["ExpDt"] ? moment.utc(event.value["ExpDt"]).local().format("MM/DD/YYYY") : null);
      }
      if (event?.value && fc==="LotExpiryDate") {
        this.vaccImmuneFG.controls["lotExpiryDate"].patchValue(event);
      }
      setTimeout(()=> {
          this.eventRaised = false;
      }, 10)
    }
  }
  drugLotRestriction(e) {
    e.preventDefault()
  }
  getDrugLots(_type?: string) {
    const data = {
        term: null,
        drugId: this.DRUG.value.id,
        bucketId: this.PRESCREFILL.value.BucketId,
        fetchInActiveRecords: false
    }
    this._cmnServ.getDrugLots(data)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(async (resp : any) => {
        if (resp && resp.length > 0) {
           this.finalLotList = resp.sort((exp1, exp2) => {
           const expDate1 = exp1 && exp1.ExpDt ? (moment(exp1.ExpDt).format("MM/DD/YYYY")) : 0;
           const expDate2 = exp2 && exp2.ExpDt ? moment(exp2.ExpDt).format("MM/DD/YYYY") : 0;
            return ((new Date (expDate1).getTime()) -  new Date (expDate2).getTime());
            });
        } else {
            this.finalLotList = [];
        }
        this.getVaccineInfo();
    });
  }
  async openMMSSearch() {
      const dropDownValues = await this._drugUtils.getDrugDefaultvalbyPost({
        DrugManufactNeeded: true,
        SigNeeded: false,
        DrugFormNeeded: true,
        VendorsNeeded: true,
      });
        const drugDetails = {
            NDC: this.NDCScaned ? (this.NDCScaned.includes("-") ? this.NDCScaned.replace(/-/g , "") : this.NDCScaned) : (this.rxInfo?.Drug?.drugcode.includes("-") ? this.rxInfo?.Drug?.drugcode.replace(/-/g , "") : this.rxInfo?.Drug?.drugcode),
            DrugNm: ""
        };
        this.drugFG = this._drugUtils.createDrugDataModal();
        const originalData = this.PRESCREFILL.value.DrugId ? await this._drugUtils.getDrugData(this.PRESCREFILL.value.DrugId) : this.drugFG.value;
        this._drugStore.storeDrugInfo(Object.assign({}, originalData));
        const modelRef = this._mdlService.open(
            DrugRefreshMmsComponent,
            {
                backdrop: "static",
                keyboard: false,
                windowClass: "md-x-lg",
            }
        );
        modelRef.componentInstance.drugDet = drugDetails;
        modelRef.componentInstance.drugId = this.PRESCREFILL.value.DrugId;
        modelRef.componentInstance.drugInfo = this._drugStore.drugInfo$["source"]["value"];
        modelRef.componentInstance.drugFG = this.drugFG;
        modelRef.componentInstance.systemData = this.systemData;
        modelRef.componentInstance.dropDownValue = dropDownValues;
        modelRef.componentInstance.forVaccnForm = true;
        modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubscribe$)).subscribe(resp => {
            modelRef.close();
            if (resp?.length) {
              this.modelRef = this._mdlService.open(CommonWarnorconfirmPopupComponent, {
                backdrop: false,
                keyboard: false, windowClass: "large--content"
                });
                this.modelRef.componentInstance.warnMsg = "The Billing Units has been updated from the refresh MMS.";
                this.modelRef.componentInstance.IsHeaderText = "Information";
                this.modelRef.componentInstance.okButton = true;
                this.modelRef.componentInstance.IsPopUpClosed.pipe(takeUntil(this.unsubsrcibe$)).subscribe(() => {
                  if(this.modelRef) {
                    this.modelRef.close();
                    this.modelRef = null;
                  }
                });
                this.vaccImmuneFG.controls["drugBillingUnits"].patchValue(resp[0].unitofMeasure ? this.BillingUnitDropDowns.find((item: any) => item.Remarks?.trim() === resp[0].unitofMeasure?.trim()).Id : null)
            }
        });
  }

  patchDiagnosis(event) {
    if (event && event.DIAGCODE) {
      this._patientSVC.getDiagnosisDetails(event.DIAGCODE)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(resp => {
              if (resp) {
                  if (!this.patDiagnis || !this.patDiagnis.TempDiagnosis) {
                      this.patDiagnis = [];
                      this.patDiagnis.TempDiagnosis = [];
                  }
                  this.patDiagnis.TempDiagnosis.push(resp);
                  const diagData = this.patDiagnis.TempDiagnosis;
                  this.patDiagnis.TempDiagnosis = [];
                  this._cdr.detectChanges();
                  this.patDiagnis.TempDiagnosis = diagData;
                  this.diagnosisList = this.patDiagnis.TempDiagnosis;
              }
      })
    }
  }
  openDiagList() {
    this.modelRef = this._mdlService.open(this.diagList, {
        backdrop: false,
        size: "lg",
        keyboard: false,
        windowClass: "modal-center-to-page"
    });
  }
  saveandCloseDiagList(value) {
    if (this.modelRef) {
        this.modelRef.close();
    }
    this.vaccImmuneFG.controls["DiagnosisId"].setValue(value.Code);
  }
  updateErrors(event) {
    if (event?.value?.Id !== 1 && event?.value?.Id !== 4 ) {
      this.isRequired = false;
      const contrlName = ["PatientRaceId", "PatientEthnicityId", "DrgManufactId", "drugBillingUnits", "AdminSiteId", "UserId", "AdminProvSuffixId", "DoseNum"
        , "VISPresentDtTm", "VISPublishDtTm", "VFCEligibilityId", "FundingSrcId", "drugLot", "lotExpiryDate"];
        contrlName.map(val => {
          if (this.vaccImmuneFG.controls[val].invalid) {
            this.vaccImmuneFG.controls[val].markAsTouched();
            this.vaccImmuneFG.controls[val].setErrors(null);
            setTimeout(()=> {
              this.vaccImmuneFG.controls[val].markAsUntouched();
            })
          }
        });
    } else {
      this.isRequired = true;
    }
  }
  updateValidations(cntrl) {
    if (this.vaccImmuneFG.controls["CompletionStatusId"].value !== 1 && this.vaccImmuneFG.controls["CompletionStatusId"].value !== 4) {
      if (this.vaccImmuneFG.controls[cntrl].invalid) {
        this.vaccImmuneFG.controls[cntrl].markAsTouched();
        this.vaccImmuneFG.controls[cntrl].setErrors(null);
        setTimeout(()=> {
          this.vaccImmuneFG.controls[cntrl].markAsUntouched();
        })
      }
    }
  }
}
