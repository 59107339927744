import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from "@angular/core";
import * as wjcGridXlsx from "@grapecity/wijmo.grid.xlsx";
import { CollectionView } from "@grapecity/wijmo";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { FilesService } from "../../../services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as wjcGrid from "@grapecity/wijmo.grid"
import { CommonUtil, WijimoUtil } from "src/app/utils";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PaginationModel } from "src/app/models/elastc-rx.model";

@Component({
    selector: "app-diag-list",
    templateUrl: "./diag-list.component.html"
})
export class DiagListComponent implements OnInit {
    paginationModel: PaginationModel = new PaginationModel();
    @Input() isPopup;
    drugWarn: any;
    disWarnWJ: CollectionView;
    actvHeaders: string[];
    totalCount:  number;
    drugDiaFG: FormGroup;
    InsuFiltersFrmGrp: FormGroup;
    pageNu = this.paginationModel.pageNumber;
    Size = this.paginationModel.pageSize;
    hideFilterForm = false;
    code: string;
    description: string;
    @ViewChild("diagnosislist")
    diagnosislist: wjcGrid.FlexGrid;
    frmReq: any;
    isDataExists = true;
    unsubscribe$: Subject<void> = new Subject();

    @Output()
    emitOnDbClick = new EventEmitter<any>();
    wjHeaders: any;
    loadResp: boolean = true;
    loading: boolean;
    progressValue: number;
    displayGrid: boolean;
    @Input()
    set FmChangeReq(ri: any) {
        this.frmReq = ri;
    }

  constructor(private _drgWar: FilesService,  private _fb: FormBuilder,
     private _modalService: NgbModal, private _wijimoUtils: WijimoUtil, private _comnUtil: CommonUtil) {
    this.drugDiaFG = this._fb.group({
        Code: [""],
        Description: [""],
        PageNum : this.pageNu,
        Pagesize: this.Size,
    });
  }

  ngOnInit() {
      this.getInfo();
      this.patchDefaultValHeaders();
      this._wijimoUtils.wjSavedData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(w => {
        if (w && w["WijmoKey"] && w["WijmoKey"] === "diagnosisListWJ") {
            this.patchDefaultValHeaders();
        }
    });
  }

    async patchDefaultValHeaders() {
        const storedWJ = await this._wijimoUtils.getWJSavedData("diagnosisListWJ");
        this.wjHeaders = this._wijimoUtils.patchDefHeader("diagnosisListWJ", storedWJ);
    }

  get Code(): FormControl {
    return this.drugDiaFG.get("Code") as FormControl;
}
get Description(): FormControl {
    return this.drugDiaFG.get("Description") as FormControl;
}
  getInfo() {
    if (document.getElementById('loader'))
        document.getElementById('loader').style.display = 'block';
    if(!this.isPopup) {
      this.loadResp = false;
      this.startProgress();
    }
  this._drgWar.getDrugDiagnosis(this.drugDiaFG.value, this.isPopup? false: true)
  .pipe(takeUntil(this.unsubscribe$))
  .subscribe(
      resp => {
          if (resp) {
            this.drugWarn = resp;
            this.generateWJ(resp);
            this.displayGrid = true;
            if (!this.isPopup)
              this.loaderDisplay();
          }
      }
  );
}

init(flex: wjcGrid.FlexGrid) {
  flex.columnHeaders.rows[0].wordWrap = true;
}
generateWJ(info: any) {
  this.totalCount = info.TotalRecords;
  if (info) {
      this.disWarnWJ = new CollectionView(
          info.TempDiagnosis.map((drugWarn, i) => {
              const j = {};
              j["Code"] = drugWarn.DIAGCODE;
              j["Diagnosis Description"] = drugWarn.DIAGDESCR;
              j["Type"] = "ICD10";
              return j;
          })
      );
  }
  if(!this.totalCount) {
    this.displayGrid = true;
    if (!this.isPopup)
      this.loaderDisplay();
    this.isDataExists = false;
  }
}
show() {
  this.hideFilterForm = !this.hideFilterForm;
}
clearSearchData() {
    this.drugDiaFG.controls["Code"].patchValue(null);
    this.drugDiaFG.controls["Description"].patchValue(null);
    this.code = "";
    this.description = "";
    this.setSize(this.paginationModel.pageSize);
}
filter() {
  const dataInfo = this.drugDiaFG.value;
    this.code = dataInfo.Code;
    this.description = dataInfo.Description;
//   this.getInfo();
    this.setPage(this.paginationModel.pageNumber);
}
setPage(page: number) {
    this.pageNu = page;
    const dataInfo = this.drugDiaFG.value;
    dataInfo.PageNum  = page;
    this.getInfo();
}
    setSize(size: any) {
    this.pageNu = this.paginationModel.pageNumber;
    this.Size = size;
    const dataInfo = this.drugDiaFG.value;
    dataInfo.Pagesize = size;
    this.getInfo();
}

onRowDblclicked(s: any, e: any) {
    if (e.which === 13) {
        e.preventDefault();
    } else {
    const ht = s.hitTest(e);
    }
    if (this.frmReq) {
    this.emitOnDbClick.emit(s.selectedRows[0].dataItem);
    }
}
focusToFirst(event) {
    if (document.getElementById("DiagCode")) {
        event.preventDefault();
        setTimeout(() => {
            document.getElementById("DiagCode").focus();
        }, 10);

    }
}
ngOnDestroy() {
    this.unsubscribe$.next();
     this.unsubscribe$.complete();
   }

   startProgress() {
    this.loading = true;
    this.progressValue = 0;
    const interval = setInterval(() => {
      if (this.loadResp) {
        this.progressValue = 100;
        clearInterval(interval);
      } else {
        this.progressValue += 25;
      }
    }, 100);
  }

  loaderDisplay() {
    this.loadResp = true;
    if (this.loadResp) {
        setTimeout(()=>{
            this.loading = false;
            if (document.getElementById('loader'))
                document.getElementById('loader').style.display = 'none';
        }, 300)
    }
}
}
