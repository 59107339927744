<div class="erx--body exprx--common-block" [ngClass]="isPopErxModel || fromRph ?'p-3':''"  *ngIf="!fromIntakeQueue"      id="erxActionListGrid">
    <div class="eprx--block__header" *ngIf="isPopErxModel">
        <div class="eprx--header__heading">
            <span class="font_size_15px"> Please select an eRx message to link</span>
        </div>
    </div>
    <div id="loader" class="progress-bar-container" >
        <div class="progress-bar--common" [style.width]="progressValue + '%'"></div>
      </div>
    <div class="eprx--block__content" *ngIf="displayGrid" [ngClass]="loading ? 'mt-0' : ''">
        <div class="row">
            <div class="col-12" >
                <div class="col-12 text-right pr-0">
                    <button class="submit print-med-guide background_orange"  (click)="toggleColorLegend()">Color Legend</button>
                </div>
                <wj-flex-grid #erxListing [headersVisibility]="'Column'" [itemsSource]="eRxListingWJ" [isReadOnly]="true" [ngClass]="!totalCount ?'no-data':''"
                    [columnPicker]="'erxListingWJ'" [style.max-height]="_wijHeight" (initialized)="init(erxListing)" [selectionMode]="'Row'" (click)="clickOnWijimo(erxListing, $event, noteslog)"  [itemFormatter]="itemFormatter">
                    <!-- <wj-flex-grid-column header="" [width]="31">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                            <span class="text-center">
                                <input class="form-check-input" type="checkbox" [id]="'check'+ item.row" name="" [(ngModel)]="item.IsSelected"
                                    [checked]="item.IsSelected" />
                                <label class="form-check-label" [for]="'check'+ item.row">
                                </label>
                            </span>
                        </ng-template>
                    </wj-flex-grid-column> -->
                    <wj-flex-grid-column [header]="'Actions'" [width]="146">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <span class="text-center">
                                <input class="form-check-input" type="checkbox" [id]="'check'+ item.row" name="" [(ngModel)]="item.IsSelected"
                                    [checked]="item.IsSelected" (click)="selectedItems(item,item.IsSelected)"/>
                                <label class="form-check-label" [for]="'check'+ item.row">
                                </label>
                            </span>
                            <span *ngIf="(item['Message Type'] === 'NewRx' || item['Message Type'] === 'REFRES' ||
                            item['Message Type'] === 'RxChangeResponse')" class="benefitCheck benefitCheckeRx Highlightfocus--benefitCheck" title="Benefit Check" (click)="navigateToBenfitCheck(item)">B</span>
                            <span *ngIf="(item['Message Type'] !== 'NewRx' && item['Message Type'] !== 'REFRES' && item['Message Type'] !== 'RxChangeResponse')"
                                title="Benefit Check is available only for New Rx, Refill Response and Change Responses" class="benefitCheck benefitCheckeRx benefitCheck-disable Highlightfocus--benefitCheck">B
                            </span>
                            <span *ngIf="item.Status === 'Awaiting Response' || item.Status === 'Error' || item.Status === 'Error In Transmission' || item.Status === 'AwaitingResponse'">
                                <i class="far fa-info-circle mt-1 actions actions--Highlightfocus--icon" title="Details" (click)="openPopUpModal1(content1, item)"></i>
                            </span>
                            <span *ngIf="item.Status === 'Ready To Process' || item.Status === 'Already Processed' || item.Status === 'Denied' || item.Status ==='ReadyToProcess' || item.Status ==='Removed'">
                                <i class="far fa-info-circle mt-1 actions actions--Highlightfocus--icon" title="Details" (click)="openPopUpModal2(content2, item)" (click)="getStatusDetails(item)"></i>
                            </span>
                            <span *ngIf=" ((item['Message Type'] === 'NewRx' || item['Message Type'] === 'REFRES') && item['Status'] === 'Ready To Process') ">
                                <i class="far fa-exchange  actions actions--Highlightfocus--icon" title="Change Request" (click)="openActionModal('ERxChange', item)"></i>
                            </span>
                            <span *ngIf=" !(((item['Message Type'] === 'NewRx' || item['Message Type'] === 'REFRES') && item['Status'] === 'Ready To Process')) ">
                                <i class="far fa-exchange  actions color_grey" title="Change Request"></i>
                            </span>
                            <span *ngIf="item.Status === 'Ready To Process' || item.Status === 'Already Processed' || item.Status === 'Denied' || item.status ==='ReadyToProcess' ">
                                <i class="far fa-history actions actions--Highlightfocus--icon" title="Patient History" (click)="routeToPatientHistory(item)"></i>
                            </span>
                            <span *ngIf="!(item.Status === 'Ready To Process' || item.Status === 'Already Processed' || item.Status === 'Denied' || item.status ==='ReadyToProcess')">
                                <i class="far fa-history actions color_grey" title="Patient History"></i>
                            </span>
                            <i *ngIf="item.Status !== 'Already Processed' && item.Status !=='Removed'" class="fa fa-times  actions danger-actve" title="Remove" (click)="openPopUpModal(content, item)"></i>
                            <i *ngIf="item.Status === 'Already Processed' || item.Status === 'Removed'" class="fa fa-times  actions color_grey" title="{{item.Status === 'Already Processed' ? 'Already processed Rx\'s with Rx# attached, cannot be removed' : 'Already removed, cannot be removed again'}}" ></i>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'Process'" [width]="64">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div class="text-center" >
                                <i *ngIf="!isPopErxModel && (((item.Status === 'Ready To Process' || item.Status === 'ReadyToProcess' || item.Status === 'Removed') &&
                                                (item['Message Type'] === 'NewRx' || item['Message Type'] === 'RxChangeResponse')) ||
                                            ((item.Status === 'Ready To Process' || item.Status === 'ReadyToProcess' || item.Status === 'Removed') &&
                                                (item['Message Type'] === 'REFRES' || item['Message Type'] === 'RxChangeResponse')) ||
                                            ((item.Status === 'Ready To Process' || item.Status === 'ReadyToProcess' || item.Status === 'Removed') &&
                                                (item['Message Type'] === 'CancelRx' || item['Message Type'] === 'RxChangeResponse')))"
                                    class="far fa-cogs  actions actions--Highlightfocus--icon" title="Process" (click)="isPopErxModel?'':getProcessInfo(item,false)"></i>
                                <i *ngIf="isPopErxModel || !(((item.Status === 'Ready To Process' || item.Status === 'ReadyToProcess' || item.Status === 'Removed') &&
                                                (item['Message Type'] === 'NewRx' || item['Message Type'] === 'RxChangeResponse')) ||
                                            ((item.Status === 'Ready To Process' || item.Status === 'ReadyToProcess' || item.Status === 'Removed') &&
                                                (item['Message Type'] === 'REFRES'|| item['Message Type'] === 'RxChangeResponse')) ||
                                            ((item.Status === 'Ready To Process' || item.Status === 'ReadyToProcess' || item.Status === 'Removed') &&
                                                (item['Message Type'] === 'CancelRx' || item['Message Type'] === 'RxChangeResponse')))"
                                    class="far fa-cogs  actions color_grey" title="{{ item.Status }}"></i>
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']"
                        *ngFor="let rxheader of wjHeaders; let i = index" [width]="rxheader['width']">
                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                            <div *ngIf="!(rxheader['hName'] === 'PatientFound' || rxheader['hName'] === 'Patient Name' || rxheader['hName'] === 'PrescriberFound' || rxheader['hName'] === 'Prescriber Name' ||
                             rxheader['hName'] === 'Drug Name' || rxheader['hName'] === 'DrugFound' || rxheader['hName'] === 'Rph Notes' || rxheader['hName'] === 'Presc Notes' || rxheader['hName'] === 'PatientPhone' || rxheader['hName'] === 'Status')"
                                title={{item[rxheader.hName]}}>
                                {{ item[rxheader['hName']] }}
                            </div>
                            <div *ngIf="rxheader['hName'] === 'PatientPhone'" title={{item[rxheader.hName]}}>
                                {{ item[rxheader['hName']] | mask : "(000)000-0000" }}
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Status'" class="text-center" [ngClass]="(item?.IsDeleted)?'delete-color-code':statusColorCodes(item?.Status)">
                                {{item[rxheader['hName']]}}
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Patient Name'">
                                <span class="text-center">
                                    <i class="far fa-user-injured fa-lg font_13"
                                        [ngStyle]="{'color': item['PatientFound'] > 1 ? '#ffc107'
                                                                                    : item['PatientFound'] == 1 ? '#51a351' : '#f6251a'
                                                                        }"
                                        [title]="item['PatientFound'] > 1? 'Multiple Match': item['PatientFound'] == 1? 'Match Found': 'Match Not Found'"></i>
                                </span> &nbsp;
                                <span [ngbTooltip]=patToolTipCont placement="left" container="body" tooltipClass="erx-patient-tooltip" container="body" [openDelay]="500">
                                    {{item[rxheader['hName']] | uppercase}}

                                    <ng-template #patToolTipCont>
                                        <div>
                                            <div class="row text-left">
                                                <div class="col-md-12">
                                                    <label class="text-decoration-underline margin_bottom_1rem">Patient Info</label>
                                                </div>
                                                <div class="col-md-12">
                                                    <label>Patient Name :</label>
                                                    <span> {{item["Patient Name"] | uppercase}}</span>
                                                </div>
                                                <div class="col-md-5">
                                                    <label>DOB :</label>
                                                    <span> {{item["PatientToolTip"][0]}}</span>
                                                </div>
                                                <div class="col-md-7">
                                                    <label>Gender :</label><span> {{item["PatientToolTip"][1]}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label>Address :</label><span> {{item["PatientToolTip"][2]}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label>Phone# :</label><span> {{item["PatientToolTip"][3] | mask : "(000)000-0000" }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </span>
                            </div>
                          
                            <div *ngIf="rxheader['hName'] === 'Prescriber Name'">
                                <span class="text-center">
                                    <i class="far fa-user-md fa-lg font_13"
                                        [ngStyle]="{'color': item['PrescriberFound'] > 1 ? '#ffc107'
                                                                                    : item['PrescriberFound'] == 1 ? '#51a351' : '#f6251a'
                                                                        }"
                                        [title]="item['PrescriberFound'] > 1? 'Multiple Match': item['PrescriberFound'] == 1? 'Match Found': 'Match Not Found'"></i>
                                </span> &nbsp;
                                <span [ngbTooltip]=preToolTipCont placement="left" container="body" tooltipClass="erx-prescriber-tooltip" container="body" [openDelay]="500">
                                    {{item[rxheader['hName']] | uppercase}}

                                    <ng-template #preToolTipCont>
                                        <div>
                                            <div class="row text-left">
                                                <div class="col-md-12">
                                                    <label class="text-decoration-underline margin_bottom_1rem">Prescriber Info</label>
                                                </div>
                                                <div class="col-md-12">
                                                    <label>Prescriber Name :</label>
                                                    <span> {{item["Prescriber Name"] | uppercase}}</span>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>NPI# : </label>
                                                    <span> {{item["PrescriberToolTip"][0]}}</span>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>DEA# : </label>
                                                    <span> {{item["PrescriberToolTip"][2]}}</span>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Prescriber Phone# : </label>
                                                    <span> {{item["PrescriberToolTip"][3] | mask : "(000)000-0000" }}</span>
                                                </div>
                                                <div class="col-md-6">
                                                    <label>Prescriber Fax# : </label>
                                                    <span> {{item["PrescriberToolTip"][4] | mask : "(000)000-0000" }} </span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label>Address : </label><span> {{item["PrescriberToolTip"][1]}}</span>
                                                </div>
                                            </div>
                                        </div>
                                </ng-template>
                                </span>
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Drug Name'">
                                <span class="text-center">
                                    <i [ngClass]="rxheader['hName'] === 'PatientFound' ? 'far fa-user-injured fa-lg font_13' : rxheader['hName'] === 'PrescriberFound' ? 'far fa-user-md fa-lg font_13' : 'far fa-prescription-bottle-alt fa-lg' "
                                        [ngStyle]="{'color': item['DrugFound'] > 1 ? '#ffc107'
                                                                                    : item['DrugFound'] == 1 ? '#51a351' : '#f6251a'
                                                                        }"
                                        [title]="item['DrugFound'] > 1? 'Multiple Match': item['DrugFound'] == 1? 'Match Found': 'Match Not Found'"></i>
                                </span>&nbsp;
                                <span [ngbTooltip]=drugToolTipCont placement="left" container="body" tooltipClass="erx-drug-tooltip"
                                    container="body" [openDelay]="500">
                                    {{item[rxheader['hName']] | uppercase}}


                                    <ng-template #drugToolTipCont>
                                        <div>
                                            <div class="row text-left">
                                                <div class="col-md-12">
                                                    <label class="text-decoration-underline margin_bottom_1rem">Drug Info</label>
                                                </div>
                                                <div class="col-md-12">
                                                    <label>Drug Name :</label>
                                                    <span> {{item["Drug Name"] | uppercase}}</span>
                                                </div>
                                                <div class="col-md-5">
                                                    <label>NDC : </label>
                                                    <span> {{item["ToolTip"][1] | mask:'AAAA0-0000-00'}}</span>
                                                </div>
                                                <div class="col-md-2">
                                                    <label>Qty : </label><span> {{item["ToolTip"][2]}}</span>
                                                </div>
                                                <div class="col-md-2">
                                                    <label>Days : </label><span> {{item["ToolTip"][3]}}</span>
                                                </div>
                                                <div class="col-md-3">
                                                    <label>Refills : </label><span> {{item["ToolTip"][4]}}</span>
                                                </div>
                                                <div class="col-md-12">
                                                    <label>Sig : </label><span> {{item["ToolTip"][5]}}</span>
                                                </div>
                                                <div *ngIf="item['dispensedHistory']?.LastFilledDate" class="col-md-12">
                                                    <label>Last Filled : </label><span> {{item['dispensedHistory']?.LastFilledDate ?
                                                        (item['dispensedHistory'].LastFilledDate | date:'MM/dd/yyyy') : ''}}</span>
                                                </div>
                                                <div *ngIf="item['dispensedHistory']?.LastEquivalentDrugFilled" class="col-md-12">
                                                    <label>Last Equivalent Drug Filled : </label><span>
                                                        {{item['dispensedHistory'].LastEquivalentDrugFilled ?
                                                        (item['dispensedHistory'].LastEquivalentDrugFilled | date:'MM/dd/yyyy') : ''}}</span>
                                                </div>
                                            </div>
                                            <div class="row max_height_200px overflow_y_auto"
                                                *ngIf="item['dispensedHistory']?.['dispensedHistory'] && item['dispensedHistory']?.['dispensedHistory']?.length > 0"
                                                >
                                                <div class="col-md-12 text-left">
                                                    <label>Last Equivalent Rxs Filled</label>
                                                    <table class="table table-bordered table-striped table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">
                                                                    Rx#
                                                                </th>
                                                                <th scope="col">
                                                                    Ref#
                                                                </th>
                                                                <th scope="col">
                                                                    Fill Date
                                                                </th>
                                                                <th scope="col">
                                                                    Status
                                                                </th>
                                                                <th scope="col">
                                                                    Qty
                                                                </th>
                                                                <th scope="col">
                                                                    Days
                                                                </th>
                                                                <th scope="col">
                                                                    Drug
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let singPres of item['dispensedHistory']['dispensedHistory']">
                                                                <td>{{singPres?.Prescription?.PrescNum}}</td>
                                                                <td>{{singPres?.PrescReFill?.ReFillNum}}</td>
                                                                <td>{{singPres?.PrescReFill?.FillDtTm ? (singPres.PrescReFill.FillDtTm | localTime | date:'MM/dd/yyyy') : ''}}</td>
                                                                <td>{{singPres?.RxBill?.StatusId === 1 ? 'UNBILLED' : singPres?.RxBill?.StatusId ===
                                                                    2 ? 'BILLED' : singPres?.RxBill?.StatusId === 3 ? 'TRANSFER IN' :
                                                                    singPres?.RxBill?.StatusId === 4 ? 'TRANSFER OUT' : singPres?.RxBill?.StatusId
                                                                    === 6 ? 'DEFERRED' : ''}}</td>
                                                                <td>{{singPres?.PrescReFill?.DispQty}}</td>
                                                                <td>{{singPres?.PrescReFill?.SupplyDays}}</td>
                                                                <td>{{(singPres?.Drug?.drugname + ' ' + singPres?.Drug?.strength + ' '+
                                                                    singPres?.Drug?.drugformname)  | uppercase}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </span>
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Rph Notes'">
                                <a [ngbTooltip]="rphToolTipCont" placement="left" class="text_transform tooltip_Notes_display"  container="body" tooltipClass="notes-tooltip" [openDelay]="500">
                                    <span [innerHTML]="(item['RphNotes'] && item['RphNotes'].length > 0) ?  item['RphNotes'][0].Note : ''"></span>
                                    <ng-template #rphToolTipCont>
                                        <div>
                                            <div class="row text-left">
                                                <div class="col-md-12" style="border-bottom: 1px solid gray;">
                                                    <label class="text-decoration-underline margin_bottom_1rem">RPH Notes</label>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" *ngFor="let rpNote of item['RphNotes']" style="border-bottom: 1px solid gray;">
                                                        <div class="col-md-12">
                                                            <label>Note :</label>
                                                            <span [innerHTML]="rpNote?.Note"> </span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Date :</label>
                                                            <span>{{rpNote.NoteModifiedDate | datetimeUtc}}</span>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>Created By :</label>
                                                            <span>{{rpNote.UserName | uppercase}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </ng-template>
                                </a>
                            </div>
                            <div *ngIf="rxheader['hName'] === 'Presc Notes'">
                                <span (click)="openPrescriberNotes(item,prescNotesContent)" *ngIf="item.PrescNotes" [ngbTooltip]="prescNotesToolTipCont" placement="left" container="body" tooltipClass="presc-notes-tooltip" [openDelay]="500">
                                    <i class="far fa-notes-medical  actions" title="Notes" aria-hidden="true"></i>
                                    <ng-template #prescNotesToolTipCont>
                                        <div>
                                            <div class="row text-left">
                                                <div class="col-md-12">
                                                    <label class="text-decoration-underline margin_bottom_1rem">Prescriber Notes</label>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <span [innerHTML]="item.PrescNotes"> </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </ng-template>
                                    <ng-template #prescNotesContent let-c="close" let-d="dismiss">
                                        <div class="modal-header">
                                            <h4 class="modal-title">Prescriber Notes</h4>
                                            <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
                                                <span aria-hidden="true" class="close-button">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body edit-patient">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="notes--block">
                                                        <div>
                                                            <div class="row">
                                                                <div class="col-lg-12 padding--right__7">
                                                                    <div class="form-group">
                                                                        <fieldset [disabled]="true">
                                                                            <textarea class="label--text__area col-md-12"  value="{{item.PrescNotes}}"></textarea>
                                                                        </fieldset>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="o">
                                               <b>O</b> OK
                                            </button>
                                        </div>
                                    </ng-template>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template wjFlexGridCellTemplate [cellType]="'ColumnHeader'" let-item="item">
                            <div title={{rxheader.hName}}>
                               <span *ngIf="!(rxheader['hName'] === 'PatientFound'  || rxheader['hName'] === 'PrescriberFound' || rxheader['hName'] === 'DrugFound')"> {{ rxheader.hName | StringSepWithSpaceOnCamCase}} </span>
                            </div>
                        </ng-template>
                    </wj-flex-grid-column>
                    <app-no-data-found *ngIf="!isDataExits"></app-no-data-found>
                </wj-flex-grid>
            </div>
        </div>
        <app-eprx-pagination *ngIf="totalCount" [TotalCount]="totalCount" (OnPageSelected)="setPage($event)" (OnSizeSelected)="setSize($event)" [GridName]="'eRxGrid'"
            [WijmoName]="flex" [PageNumber]="erxFilterData.PageNumber" (OnFocusOut)="focusToFirst($event)" [HasExport]="true"></app-eprx-pagination>
    </div>

<app-print-fax-request *ngIf="activeModal === 'faxPopUp'" [FaxInfo]="printRefWJ" [RxInfo]="rxInfoArry"
    (IsPopUpClosed)="closedFaxRequest()"> </app-print-fax-request>
</div>
<app-benfit-check
*ngIf="isBenfitCheckShow && (activeModal === 'BenfitCheck')"
[BenefitCheckDetails]="benefitCheckDetails"
[IsBenefitCheckProcess]="isBenefitCheckProcess"
[RxDays] ="rxDays"
(CloseBenfitCheckModal)="closeBenfitCheckModal($event)"
>
</app-benfit-check>
<app-erx-change-req
    *ngIf="activeModal === 'ERxChange'"
    [SelectedBenefitCheckType]="selectedBenefitCheckType"
    (IsPopUpClosed)="openActionModal($event)"
    (DrugAddEmit)="openDrugAddPopUp($event)"
    [DrugAddDetails]="drugAddFrmPopup"
    [ExData]="erxChangeData"
></app-erx-change-req>

<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label class="text-black">Are you sure you want to Remove this record?</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')" (click)="deleteErx()" appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n">
           <b>N</b> NO
        </button>
    </div>
</ng-template>
<ng-template #content1 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">eRx Request</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-sm-12">
                <div class="col-12">
                    <div class="form-group insurance--body">
                        <label class="control-label full--width">
                            <span> Transaction ID </span>
                            <textarea rows="1" cols="10" class="custom--text__area" placeholder="Transaction ID " readonly>
                                    {{
                                    awaitInfo?.TransactionId | uppercase
                                }} </textarea
                            >
                        </label>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group insurance--body">
                        <label class="control-label full--width">
                            <span> Message Date</span>
                            <textarea
                                rows="1"
                                cols="10"
                                class="custom--text__area"
                                placeholder="Message Date"
                                readonly
                            >
                            {{
                                ((eRxListingWJ?.currentItem)['Date Time'] | date: "MM/dd/yyyy")
                            }}
                                     </textarea>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group insurance--body">
                        <label class="control-label full--width">
                            <span> Message Type</span>
                            <textarea
                                rows="1"
                                cols="10"
                                class="custom--text__area"
                                placeholder=" Message Type"
                                readonly
                            >
                                    {{
                                    (eRxListingWJ?.currentItem)["Message Type"]
                                }} </textarea
                            >
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group insurance--body">
                        <label class="control-label full--width">
                            <span> Transaction Status</span>
                            <textarea
                                rows="1"
                                cols="10"
                                class="custom--text__area"
                                placeholder=" Transaction Status"
                                readonly
                            >
                                    {{
                                    (eRxListingWJ?.currentItem)["Status"]
                                }}</textarea
                            >
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group insurance--body">
                        <label class="control-label full--width">
                            <span> Error Description</span>
                            <textarea
                                rows="2"
                                cols="10"
                                class="custom--text__area"
                                placeholder=" Error Description"
                                readonly
                            >
                                {{
                                    awaitInfo?.ErrorDescription
                                        ? awaitInfo?.ErrorDescription
                                        : "--"
                                }}</textarea
                            >
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey [AltKey]="'c'">
          <span>C</span>  Cancel
        </button>
    </div>
</ng-template>

<ng-template #content2 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">eRx Result</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div
    class="col-md-12 pd-0 text-right erx_result_class"
>
    <button
        class="hotkey_success pull-right"
        type="submit"
        (click)="printDocument()"
        appShortcutKey [AltKey]="'p'"
    >
      <span>P</span>  Print
    </button>
    <button type="button" class="hotkey_success" (click)="c('Close click')" appShortcutKey [AltKey]="'c'">
        <span>C</span>Close
    </button>
</div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <div class="editpatient--body newrx--body insurance--body eRxResult">
                            <div class="row">
                                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 padding--right__7">
                                    <div class="eprx--block__content" id="tab77">
                                        <div class="row padding_1_428rem pt-0 padding_left_5rem" id="tab77">
                                            <div class="col-md-12 border_1px_solid padding_0_714rem" id="tab77">
                                                <div class="row text-center">
                                                    <div class="col-md-1">
                                                        <h2 class="padding_top_2rem">
                                                            eRx
                                                        </h2>
                                                    </div>
                                                    <div class="col-md-8 text-center">
                                                        <h5>
                                                            <label>{{ detailsInfo?.PrescName ? (detailsInfo?.PrescName | titlecase) : "--" }}, {{
                                                                detailsInfo?.PrescSpi }}
                                                            </label>
                                                        </h5>
                                                        <h5>
                                                            <label>A Clinic {{ detailsInfo?.DigestValue }}
                                                            </label>
                                                        </h5>
                                                        <p>
                                                            {{ detailsInfo?.PrescAddress }}
                                                        </p>
                                                        <div>
                                                                <label>Agent: </label>
                                                                <span>
                                                                    {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.FirstName : "" }}
                                        {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.LastName : "" }}
                                        {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.MiddleName : "" }}
                                                                </span>
                                                                <span *ngIf="(!detailsInfo?.Prescriberagent?.FirstName &&  !detailsInfo?.Prescriberagent?.LastName &&  !detailsInfo?.Prescriberagent?.MiddleName)">
                                                                    --
                                                              </span>
                                                            </div>
                                                            <div>
                                                                <label>Supervisor: </label>
                                                                <span>{{(detailsInfo?.SuperVisorName) ?  (detailsInfo?.SuperVisorName | uppercase) : '  --'}}
                                                                </span>
                                                            </div>
                                                           <div class="row">
                                                            <div class="col-6">
                                                                <label>DEA#: </label>
                                                                <span>
                                                                    {{(detailsInfo?.SuperVisorDea) ?  detailsInfo?.SuperVisorDea : ' --'}}
                                                                </span>
                                                            </div>
                                                            <div class="col-6">
                                                                <label>NPI#: </label>
                                                                <span>
                                                                    {{(detailsInfo?.SuperVisorNpi) ?  detailsInfo?.SuperVisorNpi : ' --'}}
                                                                </span>
                                                            </div>
                                                           </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>NPI#</label>
                                                            <div class="label--data">
                                                                {{ detailsInfo?.PrescNpi ? detailsInfo?.PrescNpi : "--" }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                            <label>Phone#</label>
                                                            <div class="label--data">
                                                                {{ detailsInfo?.PrescPhone ? (detailsInfo?.PrescPhone | mask : "(000) 000-0000") : "--" }}
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <label>DEA#</label>
                                                            <div class="label--data">
                                                                {{ detailsInfo?.PrescDea ? _formatsUtil.getDeaFormat(detailsInfo?.PrescDea) : "--" }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="exprx--common-block">
                                                    <div class="eprx--block__header">
                                                        <div class="eprx--header__heading word_break_all text-nowrap overflow-hidden text-truncate">
                                                            Patient - {{ detailsInfo?.PatName ? (detailsInfo?.PatName | uppercase) : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <label>ID</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.ID ? detailsInfo?.ID : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-3 ">
                                                                <label>DOB</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PatBirthDt | date : "MM/dd/yyyy" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <label>Gender</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PatGender ? detailsInfo?.PatGender : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <button class="hotkey_primary m-0" (click)="openMoreInfo()" appShortcutKey [InputKey]="'m'"><b>M</b> eRx Extra Info</button>
                                                            </div>
                                                            <div class="col-3">
                                                                <label>Phone#</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PatPhone | mask : "(000) 000-0000" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8">
                                                                <label>Address</label>
                                                                <div class="label--data" [title]="detailsInfo?.PatAddress">
                                                                    {{ detailsInfo?.PatAddress ? detailsInfo?.PatAddress : "--" }}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="exprx--common-block" *ngIf="detailsInfo?.Prescriberagent">
                                                    <div class="eprx--block__header">
                                                        <div class="eprx--header__heading">
                                                            Prescriber Agent
                                                        </div>
                                                    </div>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>First Name</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.FirstName : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                                                                <label>Last Name
                                                                </label>
                                                                <div class="label--data">
                                                                        {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.LastName : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Middle Name</label>
                                                                <div class="label--data">
                                                                        {{ detailsInfo?.Prescriberagent ? detailsInfo?.Prescriberagent?.MiddleName : "--" }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- drug -->
                                                <div class="exprx--common-block">
                                                    <div class="eprx--block__header">
                                                        <div class="eprx--header__heading">
                                                            Drug - <span [ngClass]="detailsInfo?.DrugClass && detailsInfo?.DrugClass > 1 ? 'background--orange' : ''"> {{ detailsInfo?.DrugName ? (detailsInfo?.DrugName  | uppercase) : "--" }} </span>
                                                        </div>
                                                    </div>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>Drug NDC
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.DrugNdc ? (detailsInfo?.DrugNdc | mask : "AAAA0-0000-00") : "--"}}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                                                                <label>Strength</label>
                                                                <div class="label--data" title="{{ detailsInfo?.DrugStrength ? (detailsInfo?.DrugStrength | uppercase) : '--' }}">
                                                                    {{ detailsInfo?.DrugStrength ? detailsInfo?.DrugStrength : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Form</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.DrugForm ? detailsInfo?.DrugForm : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                <label>
                                                                    Potency Unit
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.DrugPotency ? detailsInfo?.DrugPotency : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <button *ngIf="detailsInfo?.isCompoundDrug" class="hotkey_primary" (click)="openCompDrug()" appShortcutKey [InputKey]="'c'"><b>C</b>Comp. Details</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="exprx--common-block">
                                                    <div class="eprx--block__header">
                                                        <div class="eprx--header__heading">
                                                            Rx# - {{ currentDetails?.RxNo ? currentDetails?.RxNo : (detailsInfo?.RxNum ? detailsInfo?.RxNum : "--") }}
                                                        </div>
                                                    </div>
                                                    <div class="eprx--block__content">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>Auth Refills
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.RefillNum ? detailsInfo?.RefillNum : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12 ">
                                                                <label>Quantity</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.RxQty ? detailsInfo?.RxQty : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Days Supply</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.RxDays ? detailsInfo?.RxDays : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    DAW
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.RxDaw ? detailsInfo?.RxDaw : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Order Date
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ rxOrdDt ? rxOrdDt : '--'}}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Effective Date
                                                                </label>
                                                                <div class="label--data">
                                                                    {{ effectiveDate}}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Prs. order Ref
                                                                </label>
                                                                <div class="label--data" [title]="detailsInfo?.PrsOrdRef">
                                                                    {{ detailsInfo?.PrsOrdRef ? detailsInfo?.PrsOrdRef : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                                                <label>
                                                                    Diagnosis Code</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.diagnosis ? detailsInfo?.diagnosis : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="detailsInfo?.prohibitRenewalRequest">
                                                                <label>
                                                                    Renewal Request Prohibited</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.prohibitRenewalRequest ? "YES" : "NO" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="detailsInfo?.RxFillIndicator">
                                                                <label>
                                                                    Rx Fill Indicator</label>
                                                                <div class="label--data" [title]="detailsInfo?.RxFillIndicator" >
                                                                    {{ detailsInfo?.RxFillIndicator ? detailsInfo?.RxFillIndicator : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" *ngIf="detailsInfo?.PriorAuthStatus">
                                                                <label>
                                                                    Prior Authorization Status</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PriorAuthStatus ? detailsInfo?.PriorAuthStatus : "--" }}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12" *ngIf="detailsInfo?.PriorAuthNumber">
                                                                <label>
                                                                    Prior Authorization Number</label>
                                                                <div class="label--data">
                                                                    {{ detailsInfo?.PriorAuthNumber ? detailsInfo?.PriorAuthNumber : "--" }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="exprx--common-block">
                                                        <div class="row">
                                                            <div class="col-md-6 padding-0">
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label class="control-label full--width">
                                                                            <span>
                                                                                Sig Info
                                                                            </span>
                                                                            <textarea rows="2" cols="30" class="custom--text__area" placeholder="Sig Info" readonly wrap="hard">
                                                                            {{
                                                                                        detailsInfo?.RxSig
                                                                                            ? detailsInfo?.RxSig
                                                                                            : "--"
                                                                                    }}</textarea
                                                                                >
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="col-md-6 padding-0"
                                                                >
                                                                    <div
                                                                        class="col-md-12"
                                                                    >
                                                                        <div
                                                                            class="form-group"
                                                                        >
                                                                            <label
                                                                                class="control-label full--width"
                                                                            >
                                                                                <span>
                                                                                    Prescriber Notes</span
                                                                                >
                                                                                <textarea
                                                                                    rows="2"
                                                                                    cols="30"
                                                                                    class="custom--text__area"
                                                                                    placeholder="Pharmacist Note"
                                                                                    readonly
                                                                                >
     {{ detailsInfo?.RxMsg ? detailsInfo?.RxMsg : "--" }}</textarea
                                                                                >
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div
                                                                    class="col form-group "
                                                                >
                                                                    <label
                                                                        class="control-label full--width"
                                                                    >
                                                                        <span>
                                                                            The
                                                                            Prescription
                                                                            will be
                                                                            filled
                                                                            generically
                                                                            unless
                                                                            prescriber
                                                                            writes
                                                                            "DAW" in
                                                                            the box
                                                                            below</span
                                                                        >
                                                                        <textarea
                                                                            rows="1"
                                                                            cols="30"
                                                                            class="custom--text__area"
                                                                            readonly
                                                                        >
                                                    {{
                                                                                detailsInfo?.DawName
                                                                                    ? detailsInfo?.DawName
                                                                                    : "--"
                                                                            }}</textarea
                                                                        >
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-5">
                                        <div class="exprx--common-block margin_right_2_5rem mt-0">
                                            <div class="eprx--block__header">
                                                <div class="eprx--header__heading">
                                                    <div class="col-sm-12">
                                                        <span class="patient">Transaction Information
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="eprx--block__content">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Response Status</label>
                                                        <div class="label--data">
                                                            {{ responseState ? responseState : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                        <label>Transaction</label>
                                                        <div>
                                                            {{ detailsInfo?.Transaction ? detailsInfo?.Transaction : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Message Type</label>
                                                        <div class="label--data">
                                                            {{ detailsInfo?.MessageDescription ? detailsInfo?.MessageDescription : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Date</label>
                                                        <div class="label--data">
                                                            {{ (eRxListingWJ ?.currentItem)["Date & Time"] | date : "MM/dd/yyyy" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Document Ids</label>
                                                        <div class="label--data">
                                                            {{ detailsInfo?.DocIDs ? detailsInfo?.DocIDs : "--" }}
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <label>Message Ref</label>
                                                        <div class="label--data">
                                                            {{ detailsInfo?.MsgRef | uppercase }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

            <div class="exprx--common-block margin_right_2_5rem" *ngIf="detailsInfo?.Diagnoses">
                    <div class="box box-default box-solid">
                        <div class="eprx--block__header">
                            <div class="eprx--header__heading">
                                Diagnosis Details
                            </div>
                        </div>
                        <div class="box-body">
                            <div class="row">
                                <div class="col-md-12 dose--schedule__block">
                                    <table *ngIf="detailsInfo?.Diagnoses" class="table table-striped table-bordered table-sm w-100">
                                        <thead>
                                            <tr class="background_primary">
                                                <th scope="col">
                                                    CODE
                                                </th>
                                                <th scope="col">
                                                   DESCRIPTION
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let diag of detailsInfo?.Diagnoses; let k=index">
                                                <td>{{ diag.DIAGCODE | uppercase }}
                                                </td>
                                                <td>{{ diag.DIAGDESCR | uppercase}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                        <div class="row margin_right_2_5rem">
                                            <div class="col-md-12 form-group" id="tab7">
                                                <label class="control-label full--width">
                                                    <span> Other Information</span>
                                                    <textarea rows="4" cols="30" class="custom--text__area" readonly>
                                                                                                                                    {{
                                                                                                                                            detailsInfo?.OtherInfo
                                                                                                                                                ? detailsInfo?.OtherInfo
                                                                                                                                                : "--"
                                                                                                                                        }}</textarea
                                                                                                                                    >
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="col-md-12 form-group">
                                                                                                                                <label
                                                                                                                                    class="control-label full--width"
                                                                                                                                >
                                                                                                                                    <span> Messages</span>
                                                                                                                                    <textarea
                                                                                                                                        rows="4"
                                                                                                                                        cols="30"
                                                                                                                                        class="custom--text__area"
                                                                                                                                        readonly
                                                                                                                                    >
                                                                                                                                    {{
                                                                                                                                        detailsInfo?.Message
                                                                                                                                            ? (detailsInfo?.Message | uppercase)
                                                                                                                                            : "--"
                                                                                                                                    }}</textarea>
                                                                                                                                </label>


                                                                                    </div>
                                                                                    <div class="col-md-12 form-group">
                                                                                        <label
                                                                                            class="control-label full--width"
                                                                                        >
                                                                                            <span>DEA# compliance</span>
                                                                                            <textarea
                                                                                                rows="4"
                                                                                                cols="30"
                                                                                                class="custom--text__area"
                                                                                                readonly
                                                                                            >
                                                                                            {{
                                                                                                detailsInfo?.EPCSMessage
                                                                                                    ? detailsInfo?.EPCSMessage
                                                                                                    : "--"
                                                                                            }}</textarea>
                                                                                        </label>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    <!-- <div class="modal-footer">
        <button type="button" class="hotkey_primary" (click)="c('Close click')" appShortcutKey [CtrlKey]="'c'">
                <span>C</span>Close
        </button>
    </div> -->
</ng-template>
<ng-template #noteslog let-c="close" let-d="dismiss">
    <div class="modal-body edit-patient">
        <app-notes-log
            *ngIf="erxNotes"
            (CloseNotesValue)="changeNotesPopup($event)"
            [ErxInfo]="erxRowInfo"
            [NotesInfoFor]="erxRowInfo.PatientName"
        >
        </app-notes-log>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_success" (click)="c('Close click')" appShortcutKey [AltKey]="'c'">
                <span>C</span>  Close
        </button>
    </div>
</ng-template>
<ng-template #processInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col">
                <table class="table table-hover">
                    <tbody>
                        <label class="text-black">{{ processResponse }}.</label>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="closeDigitalSignatureError()" appShortcutKey InputKey="c">
            <b>C</b> Close
        </button>
    </div>
</ng-template>
<!-- <app-pat-pre-drug-check
    *ngIf="patPrecheck"
    (IsPopUpClosed)="patPrecheck = false"
    [ErxData]="selectedErxData"
></app-pat-pre-drug-check> -->

<app-erx-to-newrx-check *ngIf="patPrecheck" (RefreshGrid)="getData(erxFilterData)" (IsPopUpClosed)="patPrecheck = false;closeIntakeModal=true;openBenefitCheckModal($event)" (IsPopUpClosed1)="closeErxModal($event)" [ErxDetailsInfo]="erxDetailsData" [ErxProcessIds]="erxProcessIds" [IsFromBefitCheck]="isFromBefitCheck" [ErxData]="selectedErxData" [EMessageType]="messageType" [FromIntakeQueue]="fromIntakeQueue"></app-erx-to-newrx-check>


<ng-template #processStatus let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-md-12">
                <b>Message Type:</b> {{eRxRowData["Message Type"]}}
            </div>
            <div class="col-md-12">
                <b>Message ID:</b> {{eRxRowData["messageId"]}}
            </div>
            <div class="col-md-12">
                <b>Patient:</b> {{eRxRowData["Patient Name"]}}
            </div>
            <div class="col-md-12">
                <b>Drug:</b> {{eRxRowData["Drug Name"] | titlecase}}
            </div>
            <div class="col-md-12">
                <b>Received:</b> {{eRxRowData["Date & Time"]}}
            </div>
            <div class="col-md-12 mt-2">
                This eRx is currently being processed on another station.
            </div>
            <div class="col-md-12">
                Do you want to continue processing?
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')" (click)="recallErx()"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click');closeIntakeModal=true;processSelectedRecords(true)" appShortcutKey InputKey="n">
            <b>N</b> NO
        </button>
    </div>
</ng-template>

<app-override-log
    *ngIf="openOverride"
    [PrivMask]="privType"
    [CatId]="categoryId"
    [OverrideEndpoint]="'POST_Rx'"
    (OverrideLoginDet)="OverrideRes($event)"
></app-override-log>

<ng-template #rxChangeRespTemp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Process New Rx</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-md-12">
                There is change response message received  for this Rx which was previously sent as ChangeRequest on {{changeRequestResp?.FileDtTm | localTime | date :'MM/dd/yyyy hh:mm:ss a'}}.
                Would you like to process the Change Response message?
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')" (click)="getProcessInfo(changeRespStatus,false)" appShortcutKey InputKey="y">
            <b>Y</b> YES
        </button>
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="n">
            <b>N</b> NO
        </button>
    </div>
</ng-template>

<ng-template #cancelRxExist let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-md-12">
                There is a CancelRx message exist for this Rx. You must process the cancelRx message first. Do you want to process the Cancel Rx?
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')" (click)="getProcessInfo(cancelRxRequestData, false)"
            appShortcutKey InputKey="y"><b>Y</b> YES
        </button>
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click'); closeDigitalSignatureError()" appShortcutKey InputKey="n">
            <b>N</b> NO
        </button>
    </div>
</ng-template>

<ng-template #ErxAlreadyProcessed let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-md-12">
                The eRx you are trying to process is already processed in another station.
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')" (click)="getData(erxFilterData)" appShortcutKey InputKey="o">
            <b>O</b> Ok
        </button>
    </div>
</ng-template>



<ng-template #erxExtraInfo let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">eRx Extra Info</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Observation Measurements</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12" *ngIf="detailsInfo?.observationDetails && detailsInfo?.observationDetails.length>0 else noRecords">
                                <wj-flex-grid #previewControl [headersVisibility]="'Column'" [itemsSource]="detailsInfo?.observationDetails" [isReadOnly]="true"
                                    [selectionMode]="'None'">
                                    <wj-flex-grid-column [header]="'BODY MASS INDEX (BMI)'" [width]="300" [binding]="'biometricName'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'VALUE'" [width]="300" [binding]="'value'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'UNIT'" [width]="300" [binding]="'unit'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'OBSERVATION DATE'" [width]="300">
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                            <div> {{item['observationDate'] | date: 'MM/dd/yyyy'}} </div>
                                        </ng-template>
                                    </wj-flex-grid-column>
                                </wj-flex-grid>
                            </div>
                            <ng-template #noRecords>
                                <div class="eprx--block__footer">
                                    <div class="col-12 no-data-found">
                                        <span></span>
                                        <i class="fa fa-file"></i><br/>
                                        No Data Found!
                                    </div>
                                </div>

                            </ng-template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Observation Notes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12">
                                <textarea  [value]="detailsInfo?.observationNotes" class="form-control custom--text__area" [rows]="3" [readOnly]="true"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Benefits Co-ordination</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content erx--body margin-bottom-1rem">
                        <span *ngIf="detailsInfo?.insuranceDetails && detailsInfo?.insuranceDetails.length>0 else noRecords">
                            <wj-flex-grid #flex [headersVisibility]="'Column'" [itemsSource]="insuranceWJ"
                            [isReadOnly]="true"  [itemFormatter]="itemFormatter"  [columnPicker]="'eRxExtraInfoListWJ'" [allowSorting]="false"
                            [selectionMode]="'Row'">
                           <wj-flex-grid-column [header]="'Action'" [width]="58">
                                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                    <span>
                                         <input (click)="selectedItems(item,item.IsSelected)"  class="form-check-input" [checked]="item.IsSelected" type="checkbox" id="i" name=""
                                         [title]="(!item ['Ins. ID']) ? 'Insurance Not Found' :
                                         ((item['isSameInsurance']) ? 'Already added to patient' : false)"
                                         [disabled]="(!item ['Ins. ID']) ? true :
                                         ((item['isSameInsurance']) ? true : false)"
                                           [(ngModel)]="item.IsSelected"  (change)="checkAll($event?.target?.checked,item)" />
                                        <label class="form-check-label" for="i"></label>
                                    </span>
                                </ng-template>
                            </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeadersExtraInfo; let i = index"
                                    [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>


                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Add Selected eRx Insurance to Patient:</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content erx--body margin-bottom-1rem">

                         <div *ngIf="(detailsInfo?.insuranceDetails.length && currentDetails && currentDetails.PatientFound);  else elseBlock">
                            <button class="hotkey_primary mx-2"
                            (click)="additionalInsurances(true)"   appShortcutKey [InputKey]="'p'"><b>P</b> Add as Primary</button>
                            <button class="hotkey_primary mx-2" (click)="additionalInsurances(false)" appShortcutKey [InputKey]="'a'"><b>A</b> Add as Additional</button>
                             <!-- <button class="hotkey_primary m-2" (click)="updatePatientInfo()" appShortcutKey [InputKey]="'u'"><b>U</b> Update Patient Ins Info</button> -->
                         </div>
                         <ng-template #elseBlock>
                            <button class="inactive"
                            [title]="!(detailsInfo?.insuranceDetails?.length) ? 'Benefits Co-ordination Records Not Found' :'Patient Not Found' "> <b>P</b>Add as Primary</button>
                            <button class="inactive" [title]="!(detailsInfo?.insuranceDetails?.length) ? 'Benefits Co-ordination Records Not Found' :'Patient Not Found' "><b>A</b> Add as Additional</button>
                              <button class="inactive" [title]="!(detailsInfo?.insuranceDetails?.length) ? 'Benefits Co-ordination Records Not Found' :'Patient Not Found' "><b>U</b> Update Patient Ins Info</button>
                         </ng-template>


                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Insurances on Patient File</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content erx--body margin-bottom-1rem">
                        <span>
                            <wj-flex-grid #flex [headersVisibility]="'Column'"
                            [itemsSource]="patientInsuranceWJ" [itemFormatter]="itemFormatter"
                            [isReadOnly]="true" [columnPicker]="'insuaranceListeRxListwj'" [allowSorting]="false"
                            [selectionMode]="'Row'">
                                <wj-flex-grid-column [header]="rxheader['hName']" [binding]="rxheader['hName']" [visible]="rxheader['isVisible']" *ngFor="let rxheader of wjHeadersExtraInfoInsurance; let i = index"
                                    [width]="rxheader['width']">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item">
                                        <div>
                                            {{ item[rxheader['hName']] }}
                                        </div>
                                    </ng-template>
                                </wj-flex-grid-column>
                            </wj-flex-grid>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"  class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="o">
            <b>o</b> OK
        </button>
    </div>
</ng-template>



<ng-template #compList let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Compound Drug Info</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Cross click')"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-12">
                <div class="exprx--common-block">
                    <div class="eprx--block__header">
                        <div class="eprx--header__heading">
                            <div class="col-sm-12 padding-0">
                                <div class="row">
                                    <div class="col-md-10">
                                        <span>Compound Drug Ingredients</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="eprx--block__content">
                        <div class="row">
                            <div class="col-12" *ngIf="detailsInfo?.compoundIngredients && detailsInfo?.compoundIngredients.length>0 else noRecords">
                                <wj-flex-grid #previewControl [headersVisibility]="'Column'" [itemsSource]="detailsInfo?.compoundIngredients" [isReadOnly]="true"
                                    [selectionMode]="'None'">
                                    <wj-flex-grid-column [header]="'DRUG NAME'" [width]="230" [binding]="'DrugName'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'NDC'" [width]="230" [binding]="'DrugNdc'">
                                    </wj-flex-grid-column>
                                   <wj-flex-grid-column [header]="'STRENGTH'" [width]="'*'" [binding]="'DrugStrength'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'FORM'" [width]="'*'" [binding]="'StrengthForm'">
                                    </wj-flex-grid-column>
                                    <wj-flex-grid-column [header]="'QUANTITY'" [width]="'*'" >
                                        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-item="item" let-cell="cell">
                                            {{item['Quantity'] | number : '1.3-3'}}
                                        </ng-template>

                                    </wj-flex-grid-column>
                                </wj-flex-grid>
                            </div>
                            <!-- <ng-template #noRecords>
                                <div class="col-12 text-center">
                                    <h4>No records to display.</h4>
                                </div>
                            </ng-template> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')" appShortcutKey InputKey="o">
            <b>o</b> OK
        </button>
    </div>
</ng-template>


<ng-template #CantProcessTemp let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="c('Cross click')" >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-md-12">
                {{errorMsg}}
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" ngbAutofocus class="hotkey_primary" (click)="c('Close click')"  appShortcutKey InputKey="o">
            <b>O</b> Ok
        </button>
    </div>
</ng-template>


<ng-template #patientNotMatch let-c="close" let-d="dismiss" class="el--popup">
    <div class="modal-header">
        <h4 class="modal-title">Warning!!</h4>
        <button type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="d('Cross click');afterLotWarnClose()">
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        Same Drug Lot# with Expiration Date already exists.
    </div>
    <div class="modal-footer">
        <div class="text-right pull-right col-md-10 padding-0">
            <div>
                <button class="hotkey_primary" (click)="d('Cross click');afterLotWarnClose()" appShortcutKey InputKey="o"><b>O</b>Ok </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #PatNotMatch let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef.close()">
        <h4 header>Wrong Patient Selection Warning!</h4>
        <span body>
            <div>
                Selected patient's Date of Birth or Patient's name does not match with the eRx Prescription you selected.
            </div>
            <div class="mt-3">
                Are you sure you want to associate this eRx Prescription?
            </div>
        </span>
        <div footer class="row w-100 position-relative bottom-0 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button ngbAutofocus class="hotkey_primary" (keydown.enter)="goToErxMessageLink()"  (click)="goToErxMessageLink()" appShortcutKey
                    InputKey="y"><b>Y</b> YES</button>
                <button class="hotkey_primary" (click)="modalRef.close()" appShortcutKey InputKey="n">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>


<ng-template #RecallErxProcessing let-c="close" let-d="dismiss" class="el--popup">
    <app-custom-modal (oncrossBtnClick)="modalRef.close()">
        <h4 header>eRx Processing</h4>
        <span body>
            <div class="row" >
                <div class="col-md-12">
                   <span class="text-primary text-uppercase"> Message Type :</span> {{eRxRowData["Message Type"] | uppercase}}
                </div>
                <div class="col-md-12">
                     <span class="text-primary text-uppercase"> Patient :</span> {{eRxRowData["Patient Name"]  |uppercase}}
                </div>
                <div class="col-md-12">
                    <span class="text-primary text-uppercase"> Drug :</span>   {{eRxRowData["Drug Name"] | uppercase}}
                </div>
                <div class="col-md-12">
                    <span class="text-primary text-uppercase"> Received :</span>   {{eRxRowData["Date & Time"]}}
                </div>
                <div class="col-md-12">
                    <span class="text-primary text-uppercase"> Prescriber :</span>  {{(eRxRowData ? eRxRowData["Prescriber Name"] : '')  | uppercase}}

                </div>
            </div>
            <div class="row margin_top_2rem">
                <div class="col-md-12">
                   This eRx is currently in Removed Status.
                </div>
                <div class="col-md-12">
                    Do you want to make it ready to Process and process it?
                </div>
            </div>
        </span>
        <div footer class="row w-100 position-relative bottom-0 margin_1_0_0_1rem">
            <div class="text-right float-right col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button id="updatePresInfo" class="hotkey_primary" appShortcutKey (click)="isPopErxModel?recallErxProcessing():recallErxProcessingForregularFlow()"
                    InputKey="y"><b>Y</b> YES</button>
                <button id="cancelPresInfo" class="hotkey_primary" appShortcutKey InputKey="n" (click)="modalRef.close();processSelectedRecords(true)">
                    <b>N</b> NO</button>
            </div>
        </div>
    </app-custom-modal>
</ng-template>

<ng-template #benfitCheckSupllyDays let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">Days Supply Info</h4>
        <button
            type="button"
            [tabindex]="-1" class="close"
            aria-label="Close"
            (click)="c('Close click');closedSupplyDayaOpenedPops()"
        >
            <span aria-hidden="true" class="close-button">&times;</span>
        </button>
    </div>
    <div class="modal-body edit-patient">
        <div class="row">
            <div class="col-md-12  font-size_1_2">
                <span class="text-primary text-uppercase font-weight-bold"> Patient:</span>   {{benefitCheckDetails?.PatName |  uppercase}}
            </div>
            <div class="col-md-12  font-size_1_2">
                <span class="text-primary text-uppercase font-weight-bold"> Prescriber:</span>   {{benefitCheckDetails?.PrescName |  uppercase}}
            </div>
            <div class="col-md-12 mb-3  font-size_1_2">
                <span class="text-primary text-uppercase font-weight-bold"> Drug:</span>   {{benefitCheckDetails?.DrugName |  uppercase}}
            </div>
            <div class="col-12 mb-3 font-weight-bold font-size_1_2">
               The Prescriber did not specify Day(s) Supply for this Prescription. Please specify Day(s) Supply to Proceed.
            </div>
            <div class="col-8">
                <eprx-input [LabelText]="'Day(s) Supply'" [AutoFocus]="true"  ngbAutofocus   [IsRequired]="true"
                    [PlaceHolder]="'Day(s) Supply'" [ControlName]="'supplyDays'" [InputType]="'NUMBER'"
                    [FormGroupName]="supplyDaysFG" [MinValue]="1" [MaxValue]="999" [DecimalValues]="0" >
                </eprx-input>
             </div>
             <div class="col-12" >

            <eprx-text-area [LabelText]="'eRx Sig'" [ControlName]="'erxSig'" [ReadOnly]="true"
            [FormGroupName]="supplyDaysFG" [RxSelectId]="'erxSigId'" [PlaceHolder]="''" [MaxLength]="100"
            [Rows]="3" [IsRequired]="false">
        </eprx-text-area>
             </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button"  (keydown.enter)="openBenfitModal()"  class="hotkey_primary" (click)="openBenfitModal()" appShortcutKey InputKey="o">
            <b>O</b> Ok
        </button>
    </div>
</ng-template>
