import { Observable } from "rxjs/observable";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { constant } from "../app.constants";

@Injectable({
    providedIn: "root"
})
export class SecurityManagerService {
    constructor(private _http: HttpClient) {}

    getallRoles(pageNumber: number, pageSize: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_SecurityManger_Roles.replace(
                "{PageNumber}",
                "" + pageNumber
            ).replace("{PageSize}", "" + pageSize)
        );
    }

    deleteRole(roleId: any): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_SecurityManager_Role.replace(
                "{roleId}",
                "" + roleId
            )
        );
    }

    getRoleById(roleId: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_SecurityManager_Role.replace("{roleId}", "" + roleId)
        );
    }

    saveRole(data: any): Observable<any> {
        return this._http.post<any>(constant.POST_SecurityManger_Roles, data);
    }

    updateRole(data: any): Observable<any> {
        return this._http.put<any>(constant.PUT_SecurityManager_Role, data);
    }

    getallUsers(data) {
        return this._http.post<any>(
            constant.GET_SecurityManger_User, data
        );
    }

    addUsers(data: any): Observable<any> {
        return this._http.post<any>(
            constant.POST_SecurityManger_User_Add,
            data
        );
    }

    updateUsers(data: any): Observable<any> {
        return this._http.put<any>(constant.PUT_SecurityManger_User, data);
    }

    updatePassword(data: any): Observable<any> {
        return this._http.put<any>(constant.PUT_USER_CHNG_PASS, data);
    }

    getDropDownValues(): Observable<any> {
        return this._http.get<any>(constant.GET_SecurityManger_DropDownValues);
    }

    getUserById(userId: number): Observable<any> {
        return this._http.get<any>(
            constant.GET_SecurityManger_UserById.replace(
                "{personId}",
                "" + userId
            )
        );
    }

    changeUserStatus(personId: number, status: boolean): Observable<any> {
        return this._http.delete<any>(
            constant.DELETE_SecurityManger_UserStatus.replace(
                "{personId}",
                "" + personId
            ).replace("{status}", "" + status)
        );
    }

    sendLoginHistFilters(loginInfo: any): Observable<any> {
        return this._http.post<any>(constant.POST_SecurityManager_UsersHistory, loginInfo);
    }

    getAuditLog(Data: any, noLoader?) {
        const reqOptns = {
            headers: new HttpHeaders().set("isLoadNotReq", "true")
        }
        if (noLoader) {
            return this._http.post(constant.POST_Get_AuditLog, Data,reqOptns);
        } else {
            return this._http.post(constant.POST_Get_AuditLog, Data);
        }
    }

    getRolesAndPrivs() {
        return this._http.get(constant.GET_Privs_Roles);
    }
    putUserLockORUnlock (user: any): Observable<any> {
        return this._http.put<any>(constant.PUT_USER_LOCK_OR_UNLOCK, user);
    }

    ControlFileUpdateUnresolvedRxsForUser (id) {
        return this._http.get(constant.ControlFileUpdateUnresolvedRxsForUser_User.replace("{userId}", "" + id));
    }
}
