export const environment = {
    production: true,
    baseUrl: "https://web-app.qa.eprimerx.com/api/",
    reportWriterbaseUrl: "https://reportwriter.qa.eprimerx.com/api/",
    ePrimeRxSignalRUrl: "https://signalr-app.qa.eprimerx.com/message",
    interfaceHubUrl: "https://interfacehubapi.qa.eprimerx.com/api/",
    // formsbaseUrl: "https://localhost:7075/api/",
    formsbaseUrl: "https://forms.qa.eprimerx.com/api/",
    documentBaseurl: "https://primeclouddocuments.qa.eprimerx.com/api/",
    version: "#Version",
    logoUrl: "",
    ImageUrl: "http://localhost:4200/assets/dist/",
    baseUrlTranslations: "/",
    translationURL: "./assets/i18n/",
    DrugImage:
        "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/{imagefile}",
    PharmacyLogo:
        "https://primerxcloudqa.blob.core.windows.net/mms-pharmacy-logo-qa/{tenantId}/{logofile}",
    SentryURL: "https://6868fedd83e04c15a79c4bb2ec35985a@o95375.ingest.sentry.io/5396659",
    SentryEnvironment: "qa",
    NDCBarcodeImageUrl:
        "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/NDCBarcode.png",
        DrugBarcodeImageUrl:
        "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/DrugImageSample.png",
    RxBarcodeImageUrl:
        "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/RxBarcode.png",
    RxRefFillBarcodeImageUrl:
    "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/RxRefillFillBarcode.png",
    FiledRxImageUrl:
        "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/Label-Filed.png",
    POSBarcodeImageUrl:"https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/POSBarcode.png",
    DWTInstallFolder:"https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/DWTScanner",
    QzDownloadLink: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/qz-tray-2.2.2+1-x86_64.exe",
    QzDownloadLinkForMac: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/qz-tray-2.2.2-x86_64.pkg",
    JavaDownloadLink:"https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/openlogic-openjdk-11.0.16+8-windows-x64.msi",
    JavaDownloadLinkforMac: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/openlogic-openjdk-11.0.16+8-mac-x64.pkg",
    ControlFax: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/ControlFax.pdf",
    SingleFax: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/SingleFaxFormat.pdf",
    TransmissionRejNoCMS: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/TransmissionRejectedFax.pdf",
    TransmissionRejWithCMS: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/TransmissionRejectedFaxWithCMSForm.pdf",
    MultiFaxSingleFormat: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/MultipleFaxSingleRxFormat.pdf",
    MultiPageMultiFaxFormat: "https://primerxcloudqa.blob.core.windows.net/mms-drug-images-qa/MutiplePageMultipleFaxFormat.pdf",
    GoogleAnalyticsKey: "",
    FONTAWESOME_NPM_AUTH_TOKEN: "",
    isReleaseNotes : true,
    isGeneratorFIle : true,
    isHoa : true,
    isTimeOffSet : false,
    isReportWriter: true,
    isClinicalForm: true,
    IseRxTransferRequest: true,
    ipAddressUrl : "https://api64.ipify.org?format=json",
    // Secure Patient communication
    isSecurePatientComm: true,
    Negotiate_apiBaseUrl:'https://api.qa.eprimerx.com/primecomm',
    primecommproxy_BaseUrl:'https://primecommproxy.qa.eprimerx.com/',
    primecomm_BaseUrl:'https://primecomm.qa.eprimerx.com/'
};
