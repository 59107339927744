<div  class="exprx--common-block erx--body" [ngClass]="isPopErxModel || fromRph?'p-3':''" *ngIf="!fromIntakeQueue">
    <div class="eprx--block__header">
        <div *ngIf="!(isPopErxModel || fromRph)">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-11">
                            <div class="eprx--header__heading">
                                <span> {{ "erx.erx-heading" | translate }}</span>
                            </div>
                            <button *ngIf="isPopErxModel || fromRph" type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closePopupModal()">
                                <span aria-hidden="true" class="close-button-1">&times;</span>
                            </button>
                        </div>
                        <div class="col-1 padding_left_7rem">
                            <app-user-guide-icon [ActiveTabId]= "'eRxActionList'"></app-user-guide-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isPopErxModel || fromRph">
            <div class="eprx--header__heading">
                <span> {{ "erx.erx-heading" | translate }}</span>
            </div>
            <button *ngIf="isPopErxModel || fromRph" type="button" [tabindex]="-1" class="close" aria-label="Close" (click)="closePopupModal()">
                <span aria-hidden="true" class="close-button-1">&times;</span>
            </button>
        </div>
       
    </div>
    <div class="eprx--block__content pb-3">
        <form [formGroup]="erxFormGroup" >
            <div class="col-sm-12 padding-0">
                <div class="row">
                    <div class="secondary-search--grid col-3" *ngIf="!IsFromRxHistoryDisplay">
                        <div class="col-md-12 radiobuttons--block" [hidden]="''">
                            <div class="row" >
                                <!-- <div class="custom-control custom-radio patient--wid">
                                    <input class="custom-control-input" type="radio" [id]="1" name="SearchBy" [value]="1" (click)="
                                            getValueFromDropdown(
                                                $event.target.id
                                            )
                                        " [checked]="selectedValue === 'Patient'" />
                                    <label class="custom-control-label dash--custom__label" [for]="1">
                                        Patient
                                    </label>
                                </div>
                                <div class="custom-control custom-radio prescriber--wid">
                                    <input class="custom-control-input" type="radio" [id]="2" [checked]="
                                            selectedValue === 'Prescriber'
                                        " name="SearchBy" [value]="2" (click)="
                                            getValueFromDropdown(
                                                $event.target.id
                                            )
                                        " />
                                    <label class="custom-control-label dash--custom__label" [for]="2">
                                        Prescriber
                                    </label>
                                </div>
                                <div class="custom-control custom-radio drug--wid">
                                    <input class="custom-control-input" type="radio" [id]="3" [checked]="selectedValue === 'Drug'" name="SearchBy" [value]="3"
                                        (click)="
                                            getValueFromDropdown(
                                                $event.target.id
                                            )
                                        " />
                                    <label class="custom-control-label dash--custom__label" [for]="3">
                                        Drug
                                    </label>
                                </div> -->
                                <label>Search</label>
                                <input type="text" #searchBox id="ErxiSearchBox" name="searchBox" class="form-control search--input__box" placeholder="Search text"
                                autofocus  (change)="
                                        searchByKeyWord($event.target.value)
                                    " formControlName="SearchText" />
                            </div>
                        </div>
                    </div>
                    <div class="col-2" [ngClass]="IsFromRxHistoryDisplay ? '' : 'align-erx-field'">
                        <eprx-date-picker  [LabelText]="'erx.erx-filter.From_Date' | translate" [PlaceHolder]="''" [ControlName]="'FromDate'"
                            [FormGroupName]="erxFormGroup" [MinDate]="" [MaxDate]="minDate" [IsDisabled]="false" [ErrorDefs]="{required: 'Required'}"
                            [MarkAsTouched]="erxFormGroup?.get('FromDate')?.touched"  [AutoFocus]="IsFromRxHistoryDisplay ? true : false">
                        </eprx-date-picker>
                    </div>
                    <div class="col-2" [ngClass]="IsFromRxHistoryDisplay ? '' : 'align-erx-field'">
                        <eprx-date-picker  [LabelText]="'erx.erx-filter.To_Date' | translate" [PlaceHolder]="''" [ControlName]="'ToDate'"
                            [FormGroupName]="erxFormGroup" [MinDate]="" [MaxDate]="minDate" [ErrorDefs]="{required: 'Required'}"
                            [MarkAsTouched]="erxFormGroup?.get('ToDate')?.touched" [IsDisabled]="false">
                        </eprx-date-picker>
                    </div>


                    <div [ngClass]="isPopErxModel?'col-5 mt-3':'col-lg-5 align-button'"  *ngIf="!IsFromRxHistoryDisplay">
                        <button type="submit" *ngIf="isPopErxModel"  class="hotkey_success pull-right" id="Search2"  (click)="processRecords('erxMessageLink')"
                        (keydown.enter)="processRecords('erxMessageLink')" appShortcutKey [AltKey]="'l'">
                       <span>L</span> Link eRx
                    </button>
                        <button *ngIf="!fromRph" type="button" class="hotkey_success pull-right" id="Search" (click)="getReadyToProcess()"
                            (keydown.enter)="getReadyToProcess()" appShortcutKey [AltKey]="'D'">
                           <span>D</span> Ready to Process
                        </button>
                        <button type="submit" *ngIf="isPopErxModel || fromRph"  class="hotkey_success pull-right" id="Search2"  (click)="closePopupModal()"
                        (keydown.enter)="closePopupModal()" appShortcutKey [AltKey]="'c'">
                           <span>C</span>  CLOSE
                        </button>
                        <button title="New Rx Req" type="button" class="hotkey_success pull-right" id="NewRxReq" (click)="goToNewRxReq()"
                            (keydown.enter)="goToNewRxReq()" appShortcutKey [AltKey]="'n'">
                           <span>N</span> New Rx Req
                        </button>
                        <button *ngIf="!isPopErxModel" type="button" class="hotkey_success pull-right" id="Search" (click)="getAlreadyProcessed()"
                            (keydown.enter)="getAlreadyProcessed()" appShortcutKey [AltKey]="'a'">
                          <span>A</span>  Already Processed
                        </button>
                        <button *ngIf="hasFaxNumber && !isPopErxModel && !fromRph" type="button" class="hotkey_success mr-3 pull-right" (click)="processRecords('fax')"
                            (keydown.enter)="processRecords('fax')" appShortcutKey [AltKey]="'i'"> <span>I</span>  Fax Refill
                        </button>
                        <button *ngIf="!hasFaxNumber && !isPopErxModel && !fromRph" type="button" class="inactive pull-right" >
                            <span>I</span>
                             Fax Refill
                        </button>
                    </div>
                    <div  *ngIf="!IsFromRxHistoryDisplay" [ngClass]="IsFromRxHistoryDisplay ? 'col-lg-3 col-md-3 col-sm-3 col-xs-3' : 'col-lg-4 col-md-4 col-sm-4 col-xs-4'">
                        <eprx-select [LabelText]="
                                'erx.erx-filter.Message_Type' | translate
                            " [RxSelectId]="'MessageTypeErx'" [PlaceHolder]="'Message Type'" [ControlName]="'CategoryIds'" [FormGroupName]="erxFormGroup" [List]="erxOpCatOptions"
                            [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'" [HasMultiple]="true" (AddSelectedValue)="addMessageType($event)"  (TriggerSelectValue)="searchErxData('MessageTypeErx')">
                        </eprx-select>
                    </div>
                    <div
                    [ngClass]="IsFromRxHistoryDisplay ? 'col-lg-2 col-md-2 col-sm-2 col-xs-2' : 'col-lg-3 col-md-3 col-sm-3 col-xs-3'" >
                        <eprx-select  [LabelText]="'erx.erx-filter.Status' | translate" [PlaceHolder]="''" [ControlName]="'StatusIds'"
                            [FormGroupName]="erxFormGroup" [List]="filterStatus" [BindLabel]="'status'" [BindValue]="'value'"
                            [LabelForId]="''"  [HasMultiple]="false" (TriggerSelectValue)="searchErxData()" [IsDisabled]="isPopErxModel" [Title]="isPopErxModel ? 'Ready To Process eRxs can only be linked.' : ''">
                        </eprx-select>
                    </div>
                    <div *ngIf="IsFromRxHistoryDisplay"  class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                        <eprx-select [LabelText]="
                                'erx.erx-filter.Message_Type' | translate
                            " [RxSelectId]="'MessageTypeErx'" [PlaceHolder]="'Message Type'" [ControlName]="'CategoryIds'" [FormGroupName]="erxFormGroup" [List]="erxOpCatOptions"
                            [BindLabel]="'Remarks'" [BindValue]="'Id'" [LabelForId]="'Name'" [HasMultiple]="true" (AddSelectedValue)="addMessageType($event)"  (TriggerSelectValue)="searchErxData('MessageTypeErx')">
                        </eprx-select>
                    </div>
                    <div class="secondary-search--grid col-3" *ngIf="IsFromRxHistoryDisplay">
                        <div class="col-md-12 radiobuttons--block" [hidden]="''">
                            <div class="row"  >
                                <label>Search</label>
                                <input type="text" #searchBox id="ErxiSearchBox" name="searchBox" class="form-control search--input__box" placeholder="Search text"
                                    (change)="
                                        searchByKeyWord($event.target.value)
                                    " formControlName="SearchText" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 align-button"  *ngIf="!IsFromRxHistoryDisplay">
                        <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" id="Search" *ngIf = "enableBtn" (click)="searchErxData()" appShortcutKey [AltKey]="'f'">
                            <span>F</span>
                            Search
                        </button>
                        <button type="submit" class="inactive" *ngIf = "!enableBtn" id="Preview" id="Search" title="Search / Refresh" appShortcutKey [AltKey]="'f'">
                            <span>F</span>
                            Search
                      </button>
                        <button type="button" class="hotkey_success pull-right" id="reset" (click)="reset()" (keydown.enter)="reset()"  appShortcutKey [AltKey]="'r'">
                            <span>R</span> reset
                        </button>
                        <button  *ngIf="IseRxTransferRequest" [ngClass]="!IsSelectedRecordsList ? 'hotkey_success' : 'inactive'" type="button" class="pull-right " id="eRxTRxREQ" 
                        (click)="!IsSelectedRecordsList ? IseRxTRxREQ = true:''"  [ngbTooltip]="'eRx Transfer Request'" triggers="mouseover:mouseleave" [autoClose]="true" placement="top" 
                        tooltipClass="search-tooltip title-tooltip"
                        (keydown.enter)="IseRxTRxREQ = true" appShortcutKey [AltKey]="'q'" (ClosePopUpModal)="!closeTRxREQPopUpModal()">
                       <span>Q</span> TRx REQ 
                    </button>
                        <button *ngIf="!isPopErxModel" type="button"  class="hotkey_success pull-right" id="Search1" (click)="routeToErxReport()"
                             appShortcutKey [AltKey]="'t'">
                            <span>T</span>    Report
                        </button>
                        <button *ngIf="!isPopErxModel" type="button" class="hotkey_success pull-right" id="Search" (click)="processRecords('report')"  appShortcutKey [AltKey]="'p'"
                        (keydown.enter)="processRecords('report')"> <span>p</span> Print
                    </button>
                    <button *ngIf="!isPopErxModel" type="button" class="hotkey_success pull-right" id="Search" (click)="processRecords('Process')"  appShortcutKey [AltKey]="'o'"
                        (keydown.enter)="processRecords('Process')"> <span>o</span> Process
                    </button>
                    </div>
                    <div  class="col-lg-12 col-md-12 col-sm-12 col-xs-12  px-2 my1" *ngIf="IsFromRxHistoryDisplay">
                           <button  [ngClass]="!IsSelectedRecordsList ? 'hotkey_success' : 'inactive'" type="button" class="pull-right " id="eRxTRxREQ" 
                        (click)="!IsSelectedRecordsList ? IseRxTRxREQ = true:''"  [ngbTooltip]="'eRx Transfer Request'" triggers="mouseover:mouseleave" [autoClose]="true" placement="top" 
                        tooltipClass="search-tooltip title-tooltip"
                        (keydown.enter)="IseRxTRxREQ = true" appShortcutKey [AltKey]="'q'" (ClosePopUpModal)="!closeTRxREQPopUpModal()">
                       <span>Q</span> TRx REQ 
                    </button>
                        <button *ngIf="!isPopErxModel" type="button" class="hotkey_success pull-right" id="Search"
                            (click)="getAlreadyProcessed()" (keydown.enter)="getAlreadyProcessed()" appShortcutKey [AltKey]="'a'">
                            <span>A</span> Already Processed
                        </button>
                        <button *ngIf="!fromRph" type="button" class="hotkey_success pull-right" id="Search" (click)="getReadyToProcess()"
                            (keydown.enter)="getReadyToProcess()" appShortcutKey [AltKey]="'D'">
                            <span>D</span> Ready to Process
                        </button>
                        <button title="New Rx Req" type="button" class="hotkey_success pull-right" id="NewRxReq" (click)="goToNewRxReq()"
                            (keydown.enter)="goToNewRxReq()" appShortcutKey [AltKey]="'n'">
                            <span>N</span> New Rx Req
                        </button>
                        <button *ngIf="hasFaxNumber && !isPopErxModel && !fromRph" type="button" class="hotkey_success pull-right"
                            (click)="processRecords('fax')" (keydown.enter)="processRecords('fax')" appShortcutKey [AltKey]="'i'">
                            <span>I</span> Fax Refill
                        </button>
                        <button *ngIf="!hasFaxNumber && !isPopErxModel && !fromRph" type="button" class="inactive pull-right"> <span>I</span>
                            Fax Refill
                        </button>
                        <button *ngIf="!isPopErxModel" type="button" class="hotkey_success pull-right" id="Search"
                            (click)="processRecords('report')" appShortcutKey [AltKey]="'p'" (keydown.enter)="processRecords('report')">
                            <span>p</span> Print
                        </button>
                        <button *ngIf="!isPopErxModel" type="button" class="hotkey_success pull-right" id="Search1" (click)="routeToErxReport()"
                             appShortcutKey [AltKey]="'t'">
                            <span>T</span> Report
                        </button>
                        <button *ngIf="!isPopErxModel" type="button" class="hotkey_success pull-right" id="Search" (click)="processRecords('Process')"  appShortcutKey [AltKey]="'o'"
                        (keydown.enter)="processRecords('Process')"> <span>o</span> Process
                              </button>
                        <button type="button" class="hotkey_success pull-right" id="reset" (click)="reset()" (keydown.enter)="reset()"
                            appShortcutKey [AltKey]="'r'">
                            <span>R</span> reset
                        </button>
                        <button title="Search / Refresh" type="submit" class="hotkey_success pull-right" *ngIf = "enableBtn" id="Search" (click)="searchErxData()" appShortcutKey [AltKey]="'f'">
                            <span>F</span>
                            Search
                        </button>
                        <button type="submit" class="inactive" *ngIf = "!enableBtn" id="Preview" title="Search / Refresh" appShortcutKey [AltKey]="'f'">
                            <span>F</span>
                            Search
                      </button>
                        <button type="submit" *ngIf="isPopErxModel" class="hotkey_success pull-right" id="Search2"
                            (click)="processRecords('erxMessageLink')" (keydown.enter)="processRecords('erxMessageLink')" appShortcutKey
                            [AltKey]="'l'">
                            <span>L</span> Link eRx
                        </button>
                        <button type="submit" *ngIf="isPopErxModel || fromRph" class="hotkey_success pull-right" id="Search2"
                            (click)="closePopupModal()" (keydown.enter)="closePopupModal()" appShortcutKey [AltKey]="'c'">
                            <span>C</span> CLOSE
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<app-e-rx-trx-req *ngIf="IseRxTRxREQ" (ClosePopUpModal)="IseRxTRxREQ = false" [SelectedPatientDetailsfrmRx]="RxInfo?.Patient"> </app-e-rx-trx-req>